.boxes-section {
  padding-top: $base-section-padding;
  padding-bottom: $base-section-padding;

  .boxes-section-header {
    margin-bottom: $base-spacing;
  }

  .boxes-container {
    @include flex-container;

    &.narrow-container {
      max-width: $narrow-container-width;
    }
  }

  .box {
    @include flex-column;
    @include content-box(white, $base-font-color);
    margin-bottom: $base-spacing;
    padding: $base-spacing;

    // By default only put two boxes per row. If three boxes are present,
    // instead try to fit 3. More than 3 is probably two many, so do stick with
    // default.
    @media(min-width: $large-breakpoint) {
      @include flex-column(6);

      &[data-num-boxes="3"] {
        @include flex-column(4);
      }
    }
  }

  // Override box grid-column settings when within narrow-container
  .narrow-container {
    .box {
      @include flex-column;
    }
  }
}
