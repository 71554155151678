.locations-section {
  padding-top: $base-section-padding / 2;
  padding-bottom: $base-section-padding;

  .main-section-title {
    @include grid-column;
  
    @media(min-width: $medium-breakpoint) {
      @include grid-column(8);
    }
  }
  
  .filter {
    @include grid-column;
    margin-bottom: $base-spacing;
  
    @media(min-width: $medium-breakpoint) {
      @include grid-column(4);
    }
  }
  
  .location-address {
    line-height: $base-line-height;
    margin-bottom: $base-spacing;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .location-container {
    @include flex-container;
    margin-bottom: -$base-spacing * 1.5; // Make up for extra padding-top on location-title
  }

  .location-title {
    @include flex-column;
    padding-top: $base-spacing * 2.5; // Add padding for scroll/anchor links
  }

  .location-column {
    @include flex-column;
    align-items: stretch;

    @media(min-width: $medium-breakpoint) {
      @include flex-column(6);
    }

    @include content-box($secondary-background-color, $base-font-color);
    
    &.corporate {
      @include content-box($blue, $alt-font-color);
      
      a {
        @include alt-link-color;
      }
    }

    margin-bottom: $base-spacing;
    padding: $base-spacing;
    text-shadow: none;
  }
}

.location-name,
.location-street-address,
.location-city-state,
.location-phone,
.location-directions {
  line-height: $base-line-height;
}

.location-name {
  font-weight: $semi-bold-font-weight;
}

#map-container {
  height: 450px;
}