.video-wall-featured-section {
  @include featured-wrapper;
}

.video-wall-section {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;

  .asset-wrapper {
    flex: 1 0 auto;
    width: 100%;

    @media(min-width: $small-breakpoint) {
      width: 50%;
    }

    @media(min-width: $large-breakpoint) {
      width: 33.33333%;
    }

    @media(min-width: $extra-large-breakpoint) {
      width: 25%;
    }
  }
}
