.news-image {
  $image-width: 96px;
  $padding: 4px;
  @include box-base($secondary-background-color, $base-font-color,
    true, 0.25rem);
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  margin-right: $small-spacing;
  padding: 4px;
  width: $image-width + 2 * $padding;

  img {
    width: $image-width;
  }
}
