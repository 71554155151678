.magazine-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media(min-width: $small-breakpoint) {
    flex-direction: row;
    align-items: flex-start;
  }
}

.magazine-thumbnail {
  flex: 0 2 auto; // Shrink at twice the rate as the details pane
  margin-bottom: $base-spacing;
  max-width: 305px;
  width: 100%;

  @media(min-width: $small-breakpoint) {
    margin-right: $base-spacing;
    margin-bottom: 0;
    max-width: 195px;
  }

  @media(min-width: $large-breakpoint) {
    flex-shrink: 0;
    width: 195px;
  }
}

.magazine-thumbnail-feature {
  @media(min-width: $small-breakpoint) {
    max-width: 305px;
  }

  @media(min-width: $large-breakpoint) {
    width: 305px;
  }
}

.magazine-details {
  flex: 1 1 100%;
}

.magazine-buttons-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: $base-spacing;

  > :first-child {
    margin-bottom: $small-spacing;
  }

  @media(min-width: $large-breakpoint) {
    align-items: center;
    flex-direction: row;

    > :first-child {
      margin-bottom: 0;
      margin-right: $base-spacing;
    }
  }
}