.hero-section {
  @include asset-section;
  $padding-top: calc(#{$header-height} + #{$base-section-padding});

  .subheading {
    font-size: $large-font-size;
    font-weight: $medium-font-weight;
  }

  &.with-asset {
    &.asset-center,
    &.asset-left,
    &.asset-right {
      .section-content {
        padding-top: $padding-top;
      }
    }

    &.asset-center {
      .heading,
      .subheading {
        margin-left: auto;
        margin-right: auto;
        max-width: 900px;
      }

      // Minor improvements to alignment on smaller screens
      @media(max-width: $large-breakpoint) {
        .heading {
          margin-top: $base-spacing;
        }
  
        .asset-wrapper {
          margin-top: $base-spacing;
          margin-bottom: $base-spacing;
        }
      }
      
      @media(min-width: $large-breakpoint) {
        display: flex;    // Fix for IE
        min-height: 100vh;

        .section-content {
          min-height: 100vh;
          padding-top: $padding-top;
          width: 100%;
        }
      }

      // Larger video on large screens
      @media(min-width: $extra-large-breakpoint) {
        .asset-wrapper {
          max-width: 716px;
        }
      }
    }
  }

  &.without-asset {
    padding-top: $padding-top;
  }
}
