// Update base .overlay-container styles
.overlay-container.background-customers {
  &:after {
    background-image: url("/img/customers.png");
    background-position: center 0px;
    background-size: 1200px 450px;
    opacity: 0.16;
    animation: slide 20s linear infinite;

    @keyframes slide {
      from {
        background-position: center 0px;
      }
    
      to {
        background-position: center 450px;
      }
    }
  }
}
