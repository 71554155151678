.awards {
  column-gap: 2.5 * $base-spacing;
  column-count: 1;
  margin-top: 2 * $base-spacing;

  @media(min-width: $small-breakpoint) {
    column-count: 2;
  }
  
  @media(min-width: $medium-breakpoint) {
    column-count: 3;
  }

  @media(min-width: $large-breakpoint) {
    column-count: 4;
  }
}

.award {
  break-inside: avoid;
  line-height: $short-line-height;
  margin-bottom: 2.5 * $base-spacing;

  .award-image-wrapper {
    @include box-base($secondary-background-color, $base-font-color,
      true, 0.25rem);
    display: inline-block;
    width: 100%;

    img {
      width: 100%;
      border: 4px solid white;
    }
  }

  .award-title {
    @include heading-link-color;
  }
}