.custom-select {
  $option-spacing: 0.5em;
  position: relative;
  width: 100%;
  z-index: 2;

  .selected {
    background: $secondary-background-color;
    border: $base-border;
    color: $plain-action-color;
    cursor: pointer;
    font-weight: $medium-font-weight;
    padding: $option-spacing;
  }

  .dropdown-icon {
    color: $light-font-color;
    float: right;
  }

  .options {
    border-left: $base-border;
    border-right: $base-border;
    border-bottom: $base-border;
    cursor: pointer;
    display: none;
    position: absolute;
    width: 100%;
  }

  .option {
    background: $secondary-background-color;
    color: $plain-action-color;
    cursor: pointer;
    display: block;
    font-weight: $medium-font-weight;
    padding: $option-spacing;
    position: relative;

    &:hover,
    &:focus {
      background: darken($secondary-background-color, 5%);
    }
  }

  &.open {
    .selected {
      border-bottom: none;
    }

    .options {
      display: block;
    }
  }
}
