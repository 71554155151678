.asset-container {
  align-items: center;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: $base-floating-box-shadow;
  cursor: pointer;
  display: flex;
  height: 0;
  justify-content: center;
  outline: 1px solid rgba(white, 0.5);
  padding-bottom: $sixteenNine;
  position: relative;
  transition: box-shadow $base-duration $base-timing;
  width: 100%;
    
  &:before {
    @include gradient-overlay($base-shadow-color);
    transition: opacity $base-duration $base-timing;
  }

  &:hover {
    box-shadow: $base-floating-box-shadow-hover;
  }

  &:hover,
  &:focus {
    &:before {
      opacity: 0.75;
    }
  }
}

.asset-container-alt {
  box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.25);
  outline: none;
  position: relative;

  &:before {
    @include base-overlay($base-shadow-color);
    opacity: 0.125;
    transition: opacity $base-duration $base-timing;
  }

  &:hover,
  &:focus {
    box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.125), 0 10px 40px 0 rgba(0, 0, 0, 0.25);

    &:before {
      opacity: 0;
    }
  }
}

.asset-icon-container {
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
}
		
.asset-icon {
  $small-size: 50px;
  $large-size: 60px;
  align-items: center;
  background-color: rgba(#000, 0.75);
  border: 2px solid white;
  color: white;
  display: flex;
  flex: 0 0 auto;
  font-size: 1.5em;
  height: $small-size;
  justify-content: center;
  opacity: 0.75;
  padding-left: 2px;
  text-shadow: none;
  transition: opacity $base-duration $base-timing, 
    background-color $base-duration $base-timing;
  width: $small-size;

  @media (min-width: $large-breakpoint) {
    font-size: 2em;
    height: $large-size;
    width: $large-size;
  }

  &:hover,
  &:focus {
    background-color: $action-color;
    opacity: 1;
  }
}

.asset-icon-alt {
  border: none;
  background: $action-color;
  opacity: 0.85;
}

.asset-title {
  color: $alt-font-color;
  font-size: $base-font-size;
  font-weight: $semi-bold-font-weight;
  line-height: $heading-line-height;
  padding: $small-spacing;
  position: absolute;
  left: 0; bottom: 0;
  text-align: left;
  text-shadow: $base-text-shadow;
  z-index: 1;
}

.youtube-embed {
  height: 100%;
  position: absolute;
  right: 0;
  top: 0; 
  width: 100%;
}