.contact-section {
  padding-top: $base-section-padding;
  padding-bottom: $base-section-padding;

  .direct-contact-info {
    margin-bottom: $base-spacing;
  }
}

.contact-form-column {
  @include grid-column;
  margin-bottom: $base-spacing * 2;

  @media(min-width: $large-breakpoint) {
    @include grid-column(8);
    margin-bottom: 0;
  }
}

.contact-aside-column {
  @include grid-column;

  @media(min-width: $large-breakpoint) {
    @include grid-column(4);
  }
}