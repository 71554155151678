$sub-nav-width: 228px; // Without search item
$nav-duration: 300ms; 
$nav-level-one-background: #f0f0f0;
$nav-level-two-background: #e0e0e0;

// Show only when nav is open
.body-overlay {
  background: #000;
  display: block;
  opacity: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  transition: opacity $nav-duration $base-timing, visibility $nav-duration $base-timing;
  visibility: hidden;
  z-index: 30;

  // This is enabled via navbar interaction
  &.enable {
    opacity: 0.6;
    visibility: visible;
  }

  // This is enabled via site-popup
  &.popup-enable {
    opacity: 0.6;
    visibility: visible;
  }
}

// Prevent body from scrolling
body.no-scroll {
  overflow: hidden;
}

// Navbar styles are defined in the order
// 1. Mobile
// 2. Desktop (transparent background)
// 3. Desktop (solid background)

// 1. Mobile

#edsi-page-header {
  background: $secondary-background-color;
  box-shadow: 0rem 0.25rem 0.25rem $light-shadow-color;
  display: block;
  position: fixed;
  top: 0;
  transition: background-color $nav-duration $base-timing, box-shadow $nav-duration $base-timing;
  width: 100%;
  z-index: 42;
}

.header-container {
  @include base-container;
}

.header-content {
  @include grid-column;
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: $header-height;
  position: relative;
}

.header-base {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  z-index: 2; // Make sure logo is above navbar
}

.header-logo-container {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-start;
  line-height: 0;
}

.header-logo {
  padding: 6px 0; // Roughly fill header space
  width: 192px;
}

.header-nav-toggle-mobile {
  align-items: center;
  color: $plain-action-color;
  cursor: pointer;
  display: flex;
  flex: 0 1 auto;
  font-size: $base-font-size-px;
  font-weight: $medium-font-weight;
  justify-content: center;
  padding: 10px; // roughly fill header space

  &:hover,
  &:focus {
    background: $nav-level-one-background;
  }
}

.nav-mobile-icon {
  color: $light-font-color;
  margin-right: $small-spacing-px / 2;
}

.header-nav {
  background: $secondary-background-color;
  bottom: 0;
  flex: 1 0 auto;
  font-size: $small-font-size-px;
  left: -100vw;
  line-height: $short-line-height;
  overflow: auto;
  position: fixed;
  top: $header-height;
  transition: background $nav-duration $base-timing, left $nav-duration $base-timing,
    visibility $nav-duration $base-timing;
  visibility: hidden;
  width: 100vw;

  .nav-item {
    border-bottom: 1px solid $base-border-color;
    color: $plain-action-color;
    cursor: pointer;
    display: block;
    font-weight: $medium-font-weight;
    transition: background $nav-duration $base-timing, 
      color $nav-duration $base-timing;
    padding: $small-spacing-px $base-gutter;

    .dropdown-icon {
      color: $light-font-color;
      margin-left: 6px;
      transition: background $nav-duration $base-timing,
        color $nav-duration $base-timing;
    }

    &.parent,
    &.top-level {
      font-weight: $semi-bold-font-weight;
    }

    &:hover,
    &:focus {
      background: $nav-level-one-background;
    }

    &.search-box-toggle {
      display: none;
    }
  }

  &.visible-mobile {
    left: 0;
    visibility: visible;
  }

  .desktop-only {
    display: none;
  }
}

.header-nav-list {
  li {
    margin-bottom: 0;
  }
}

.sub-nav,
.nested-sub-nav {
  background: $nav-level-one-background;
  display: none;

  &.open {
    display: block;
  }

  .nav-item {
    &:hover,
    &:focus {
      background: $nav-level-two-background;
    }
  }
}

.solid-navbar main {
  padding-top: $header-height;
}

.header-search-box {
  padding: $small-spacing-px $base-gutter;
}

// 2. Desktop

@media (min-width: $navbar-collapse-breakpoint) {
  #edsi-page-header {
    background: transparent;
    box-shadow: none;
  }

  .header-nav-toggle-mobile {
    display: none;
  }

  .header-content {
    flex-direction: row;
    justify-content: flex-start;
  }

  .header-logo-container {
    flex: 0 0 auto;
    justify-content: center;
  }

  .header-nav {
    background: transparent;
    position: static;
    visibility: visible;
    width: auto;

    .nav-item {
      border-bottom: none;
      color: $alt-action-color;
      padding: ($small-spacing-px / 2) $small-spacing-px;

      .dropdown-icon {
        color: $alt-light-action-color;
      }

      &.top-level {
        align-items: center;
        display: flex;
        height: 100%;
        padding-left: 12.8px;
        padding-right: 12.8px;
        transition: padding-left $nav-duration $base-timing, 
          padding-right $nav-duration $base-timing,
          background $nav-duration $base-timing,
          color $nav-duration $base-timing;

        @media (min-width: $navbar-narrow-breakpoint) {
          padding-left: 17.6px;
          padding-right: 17.6px;
        }
      }

      &:hover,
      &:focus {
        background: transparent;
        color: $alt-action-color-highlight;

        .dropdown-icon {
          color: $alt-light-action-color-highlight;
        }
      }
    }

    .mobile-only {
      display: none;
    }

    .desktop-only {
      display: inline;
    }
  }

  .header-nav-list {
    align-content: stretch;
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: flex-end;
  }

  .sub-nav {
    background: $secondary-background-color;
    box-shadow: 0.25rem 0.25rem 0.25rem $light-shadow-color;
    font-size: $smaller-font-size-px;
    position: absolute;
    width: $sub-nav-width;

    &.open-desktop {
      display: block;
    }

    .nav-item {
      align-items: center;
      color: $plain-action-color;
      display: flex;
      height: 56px;
      justify-content: space-between;

      .dropdown-icon {
        color: $light-font-color;
        margin-left: $small-spacing;
      }

      &:hover,
      &:focus {
        background: $nav-level-one-background;
        color: $plain-action-color;

        .dropdown-icon {
          color: $light-font-color;
        }
      }
    }

    .sub-nav-hover {
      &.sub-nav-open {
        .nav-item {
          background: $nav-level-one-background;
        }
      }
    }
  }

  .nested-sub-nav {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 100%;
    width: 300px;

    &.open-desktop {
      display: flex;
    }

    .nested-column {
      flex: 1 1 200px;
    }

    .nav-item {
      background: $nav-level-one-background;

      &:hover,
      &:focus {
        background: $nav-level-two-background;
      }
    }
  }

  .header-search-box {
    background: $secondary-background-color;
    box-shadow: 0.25rem 0.25rem 0.25rem $light-shadow-color;
    display: none;
    padding-left: $small-spacing-px;
    padding-right: $small-spacing-px;
    position: absolute;
    top: $header-height;
    right: 0;
    width: 320px;

    &.open {
      display: block;
    }
  }

  // 3. Desktop (solid background)

  &.solid-navbar {
    #edsi-page-header {
      background: $secondary-background-color;
      box-shadow: $light-floating-box-shadow;
    }
  }

  #edsi-page-header.solid-bg {
    background: $secondary-background-color;
    box-shadow: $light-floating-box-shadow;
  }

  &.solid-navbar,
  &.solid-bg {
    .header-nav {
      .nav-item {
        background: transparent;
        color: $plain-action-color;
        cursor: pointer;

        .dropdown-icon {
          color: $light-font-color;
        }

        &:hover,
        &:focus {
          background: $nav-level-one-background;
        }
      }

      // Override .nav-item style set above to match original setting
      .nested-sub-nav {
        .nav-item {
          &:hover,
          &:focus {
            background: $nav-level-two-background;
          }
        }
      }
    }
  }
}
