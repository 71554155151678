.blog-post-latest-container {
  @include flex-container;
  align-items: flex-start;
  margin-top: $base-spacing;
  margin-bottom: $base-spacing;
}

.blog-post-latest-wrapper {
  @include flex-column;
  
  @media (min-width: $medium-breakpoint) {
    @include flex-column(4);
  }
}

.blog-post-latest-wrapper {
  margin-bottom: $base-spacing * 2;
}

.blog-post-latest-wrapper {
  .thumbnail {
    display: block;
    max-height: 0;
    overflow: hidden;
    padding-bottom: $sixteenNine;
    position: relative;

    &:after {
      @include gradient-overlay($light-shadow-color);
      transition: opacity $base-duration $base-timing;
    }

    &:focus,
    &:hover {
      &:after {
        opacity: 0;
      }
    }
  }

  .title {
    a {
      color: $base-font-color;
      display: inline-block;
      font-weight: $semi-bold-font-weight;
      line-height: $short-line-height;
      padding-top: $small-spacing;
      padding-bottom: $small-spacing;

      &:focus,
      &:hover {
        color: $action-color;
      }
    }
  }
}