.columns-section {
  .columns-section-header {
    margin-bottom: $base-spacing;
  }

  .columns-container {
    @include flex-container;

    &.narrow-container {
      max-width: $narrow-container-width;
    }
  }

  .column {
    @include flex-column;
    margin-bottom: $base-section-padding;
  
    // By default only put two columns per row. If three columns are present,
    // instead try to fit 3. More than 3 is probably too many, so stick with
    // default.
    @media(min-width: $large-breakpoint) {
      @include flex-column(6);
     
      &[data-num-columns='1'] { 
        @include flex-column(12); 
      }

      &[data-num-columns='3'] { 
        @include flex-column(4); 
      }
    }
  }

  // Override column grid-column settings when within narrow-container
  .narrow-container {
    .column {
      @include flex-column;
    }
  }
}

//////////////////
// New Stuff Below
//////////////////

.flex-container {
  @include flex-container;

  &.dropdown-container {
    z-index: 3;
  }
}

.flex-narrow-container {
  @include flex-container;
  max-width: $narrow-container-width;
}

.flex-column {
  @include flex-column;
  display: flex;

  @media(min-width: $large-breakpoint) {
    &.two-columns {
      @include flex-column(6);
    }
    
    &.three-columns { 
      @include flex-column(4); 
    }

    &.four-columns { 
      @include flex-column(3); 
    }
  }
}

.column-heading {
  margin-bottom: $small-spacing / 2;
}

.base-box-color-none {
  background: $secondary-background-color;

  &::before, 
  &::after { 
    background: darken($secondary-background-color, 15%); 
  } 
}

.base-box-color-blue {
  background: $blue;
  &::before, 
  &::after { background: darken($blue, 15%); } 
}
.base-box-color-green {
  background: $green;
  &::before, 
  &::after { background: darken($green, 15%); } 
}
.base-box-color-orange {
  background: $orange;
  &::before, 
  &::after { background: darken($orange, 15%); } 
}
.base-box-color-purple {
  background: $purple;
  &::before, 
  &::after { background: darken($purple, 15%); } 
}
.base-box-color-red {
  background: $red;
  &::before, 
  &::after { background: darken($red, 15%); } 
}
.base-box-color-teal {
  background: $teal;
  &::before, 
  &::after { background: darken($teal, 15%); } 
}

.icon-box-none {
  height: 2.5rem;
  width: 2.5rem;
}

.icon-box-flat,
.icon-box-perspective {
  align-items: center;
  display: flex;
  height: 3rem;
  justify-content: center;
  padding: $small-spacing / 2;
  width: 3rem;
}

.icon-box-perspective {
  $depth: 0.25rem;
  @include box-base-perspective($depth);
  // Make up for extra space perspective effect takes up
  margin-right: $depth;
}

.content-box {
  width: 100%;
}

// Override background fill text color
.background-fill {
  .content-box {
    color: $base-font-color;
    text-shadow: none;
  }
}

.content-box-none {
  margin-bottom: $small-spacing;
}

.content-box-flat,
.content-box-perspective {
  margin-bottom: $base-spacing;
  padding: $small-spacing;
}

.content-box-flat {
  border: $base-border;

  &.base-box-color-blue {
    border: 1px solid darken($blue, 15%);
  }
  &.base-box-color-green {
    border: 1px solid darken($green, 15%);
  }
  &.base-box-color-orange {
    border: 1px solid darken($orange, 15%);
  }
  &.base-box-color-purple {
    border: 1px solid darken($purple, 15%);
  }
  &.base-box-color-red {
    border: 1px solid darken($red, 15%);
  }
  &.base-box-color-teal {
    border: 1px solid darken($teal, 15%);
  }
}

.content-box-perspective {
  $depth: 0.5rem;
  @include box-base-perspective($depth);
  // Make up for extra space perspective effect takes up
  margin-right: $depth;
  margin-bottom: $base-spacing + $depth;
}

// Base SVG icon config
.svg-icon {
  svg { 
    max-height: 100%;
    width: 100%;
  }
}

// SVG icons inline
.svg-icon-inline {
  display: inline-block; 
  line-height: 1;
  vertical-align: middle; 
  
  svg {
    max-height: 1em;
    width: 1em;
  }
}

// SVG icon colors
.svg-icon-color-base { svg { fill: $base-font-color; } }
.svg-icon-color-blue { svg { fill: $blue; } }
.svg-icon-color-green { svg { fill: $green; } }
.svg-icon-color-orange { svg { fill: $orange; } }
.svg-icon-color-purple { svg { fill: $purple; } }
.svg-icon-color-red { svg { fill: $red; } }
.svg-icon-color-teal { svg { fill: $teal; } }
.svg-icon-color-alt { svg { fill: $alt-font-color; } }

// SVG icons as links
.svg-icon-light-link { 
  svg { 
    fill: $light-font-color;
    transition: fill $base-duration $base-timing;
  } 
  
  &:hover,
  &:focus,
  &:active {
    svg { fill: $plain-action-color } 
  }  
}

// Base font icon config
.font-icon {
  font-size: 1.875rem;

  // Center font-icons
  [class^="icon-"], [class*=" icon-"] {
    display: flex;
    justify-content: center;
  }
}

.font-icon-color-base { color: $base-font-color; }
.font-icon-color-blue { color: $blue; }
.font-icon-color-green { color: $green; }
.font-icon-color-orange { color: $orange; }
.font-icon-color-purple { color: $purple; }
.font-icon-color-red { color: $red; }
.font-icon-color-teal { color: $teal; }
.font-icon-color-alt { color: $alt-font-color; }
