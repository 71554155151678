.callout-section {
  padding-top: $base-section-padding;
  padding-bottom: $base-section-padding;

  .callout-content {
    @include grid-column;

    // Override boxes default margin
    .box {
      margin-bottom: 0;
    }
  }
}