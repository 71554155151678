.slider-section {
  padding-top: $base-section-padding;
  padding-bottom: $base-section-padding;

  // Contains optional heading and description
  // Adds space between heading and videos
  .slider-header {
    @include base-container;
    margin-bottom: $base-spacing;
    padding-left: $base-gutter;
    padding-right: $base-gutter;
    text-align: center;

    &.narrow-content {
      max-width: $narrow-container-width;
    }
  }
}
