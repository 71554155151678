blockquote {
  $border-width: 4px;
  $padding: $base-spacing;
  border-left: $border-width solid $action-color;
  color: $action-color;
  font-size: $large-font-size;
  font-weight: $semi-bold-font-weight;
  margin-top: 0;
  margin-bottom: $base-spacing;
  margin-left: 0;
  padding: 0 0 0 calc(#{$padding} - #{$border-width});

  &:last-of-type {
    margin-bottom: 0;
  }

  footer {
    color: $light-font-color;
    font-size: $base-font-size;
    font-weight: $base-font-weight;
    margin-top: $base-spacing;
  }

  p {
    // Remove bottom margin so that within subsections there is no margin-bottom
    // (margin-bottom) is removed from the last-child, so we don't want the p
    // within the blockquote to have margin-bottom either)
    // Added margin-top to the blockquote footer (above) to compensate in case footer is present
    margin-bottom: 0 !important;
  }
}