.text-section {
  padding-top: $base-section-padding;
  padding-bottom: $base-section-padding;

  .subsection {
    margin-bottom: $base-spacing;

    // Remove margin-bottom from last subsection
    &:last-of-type {
      margin-bottom: 0;
    }

    // Remove margin-bottom from last element within subsection
    > :last-child {
      margin-bottom: 0;
    }
  }

  // Padding for images added through redactor
  figure {
    padding-top: $small-spacing;
    padding-bottom: $small-spacing;
  }
}
