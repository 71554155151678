body {
  color: $base-font-color;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
  // Only affects Mac OS, makes text appear thinner than 
  // subpixel-antialiased (default). Windows rendering is
  // somewhere in between subpixel-antialiased and antialiased
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font-family;
  font-size: modular-scale(1);
  line-height: $heading-line-height;
  margin: 0 0 1rem 0;
}

h1, h2 {
  font-weight: $extra-bold-font-weight;

  // Override default bold font weight
  b,
  strong {
    font-weight: $extra-bold-font-weight;
  }
}

h3,
h4,
h5,
h6 {
  font-weight: $bold-font-weight;

  // Override default bold font weight
  b,
  strong {
    font-weight: $bold-font-weight;
  }
}

h1 { font-size: $h1-font-size; }
h2 { font-size: $h2-font-size; }
h3 { font-size: $h3-font-size; }
h4 { font-size: $h4-font-size; }

@media (max-width: $medium-breakpoint) {
  h1 { font-size: $h1-font-size-mobile; }
  h2 { font-size: $h2-font-size-mobile; }
  h3 { font-size: $h3-font-size-mobile; }
}

// Goes within heading tag, scaled down to 75% font-size
.base-subheading,
.base-subheading-alt {
  font-size: 0.75em;
  font-weight: $bold-font-weight;
}

.base-subheading {
  color: $light-font-color;
}

.base-subheading-alt {
  color: $alt-light-font-color;
  text-shadow: $base-text-shadow;
}

// Temporarily wrap with .pillar-page class to make sure styles take effect
.pillar-page {
  .page-break-heading {
    margin-bottom: 0; 
    // Align left on mobile
    @media (max-width: $large-breakpoint) {
      text-align: left;
    }
  }

  .page-break-heading-with-nav {
    max-width: 600px;
  }

  // Increase headings site-wide to match?
  h1.page-break-heading { 
    font-size: 2.25rem;
    
    @media (min-width: $large-breakpoint) {
      font-size: 2.5rem; 
    }    
  }
}

p {
  margin: 0 0 $small-spacing;
}

b,
strong {
  font-weight: $semi-bold-font-weight;
}

a {
  color: $action-color;
  font-weight: $semi-bold-font-weight;
  text-decoration: none;
  transition: color $base-duration $base-timing;

  &:active,
  &:focus,
  &:hover {
    color: $action-color-highlight;
  }
}

hr {
  border-bottom: $base-border;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: $base-spacing 0;
}

.inline-icon {
  color: $light-font-color;
}

.strike-through {
  text-decoration: line-through;
}

.tooltip-text {
  border-bottom: 1px dashed $light-font-color;
  font-style: normal;
  font-weight: $medium-font-weight;
}

// Mouse text selection styles
::selection {
  background: $action-color;
  color: $alt-action-color-highlight;
  -webkit-text-fill-color: initial; // To override hero section gradient text
}
