.blog-posts-container {
  display: flex;
  margin-left: -$base-spacing;
  margin-right: -$base-spacing;
}

.blog-post-summary {
  flex: 0 1 auto;
  margin-left: $base-spacing;
  margin-right: $base-spacing;
  width: 20em;

  .thumbnail-image {
    background: $blue;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    height: 156px;
  }

  .category {
    color: rgba(0,0,0,0.5);
    display: block;
    font-weight: $semi-bold-font-weight;
    margin-top: $small-spacing;
    text-transform: uppercase;
  }

  .title {
    color: $base-font-color;
    display: block;
    font-weight: $semi-bold-font-weight;
    margin-top: $small-spacing;

    &:focus,
    &:hover {
      color: $action-color;
    }
  }

  .post-summary {
    display: block;
    margin-top: $small-spacing;
  }
}