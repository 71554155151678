// Expand video to full size of modal while maintaining aspect ratio
.video-modal-wrapper {
  position: relative;
  padding-bottom: $sixteenNine;
  height: 0;

  iframe {
    position: absolute;
    background: black;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
