@mixin link-color($color, $highlight-color) {
  color: $color;

  &:hover,
  &:focus,
  &:active {
    color: $highlight-color;
  }  
}

@mixin base-link-color {
  @include link-color($action-color, $action-color-highlight);
}

@mixin heading-link-color {
  @include link-color($base-font-color, $action-color);
}

@mixin light-link-color {
  @include link-color($light-font-color, $plain-action-color);
}

@mixin alt-link-color {
  @include link-color($alt-action-color, $alt-action-color-highlight);
}

@mixin alt-link-highlight-color {
  @include link-color($alt-action-color-highlight, $alt-action-color);
}

// Offset when jumping to in page anchor links
:target:before {
  content: "";
  display: block;
  height: calc(#{$header-height} + #{$base-spacing});
  margin-top: calc(-#{$header-height} - #{$base-spacing});
}

.heading-link {
  @include heading-link-color;
  font-weight: inherit; // Don't overwrite h style font weights
}

.light-link {
  @include light-link-color;
}

.highlight-color-purple {
  a {
    @include link-color($purple, darken($purple, 20%));
  }
}

.highlight-color-red {
  a {
    @include link-color($red, darken($red, 20%));
  }
}

.highlight-color-teal {
  a {
    @include link-color($teal, darken($teal, 20%));
  }
}

.highlight-color-orange {
  a {
    @include link-color($orange, darken($orange, 20%));
  }
}

.highlight-color-green {
  a {
    @include link-color($green, darken($green, 20%));
  }
}
