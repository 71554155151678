.blog-post-summary-wrapper {
  margin-bottom: $base-spacing;

  .author {
    display: inline-block;
    font-size: $small-font-size;
    padding-right: 4px;
  }

  .date {
    color: $light-font-color;
    display: inline-block;
    font-size: $small-font-size;
  }
}