.industry-images-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.industry-image-column {
  margin-bottom: $base-spacing;
  flex: 0 1 auto;
  padding-left: 14px;
  padding-right: 14px;
}

.industry-image {
  $border: 4px;
  @include image-box($secondary-background-color, $base-font-color, 96px, $border);
  position: relative;

  .title {
    $padding: $small-spacing * 0.5;
    color: $alt-font-color;
    font-size: $smallest-font-size;
    font-weight: $semi-bold-font-weight;
    line-height: $heading-line-height;
    position: absolute;
    right: $padding;
    bottom: $padding;
    left: $padding;
    text-shadow: $base-text-shadow;
  }

  .industry-overlay {
    @include gradient-overlay($base-shadow-color);
    top: $border;
    right: $border;
    bottom: $border;
    left: $border;
    transition: opacity $base-duration $base-timing;
  }
}
