@mixin featured-wrapper {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  overflow: hidden; // Hide any overhanging images in case of rounding issues
  width: 100%;

  @media(min-width: $medium-breakpoint) {
    flex-direction: row;
  }

  .primary {
    flex: 1 1 auto;
    line-height: 0;

    @media(min-width: $medium-breakpoint) {
      flex: 2 1 0px;  // Set default size to 0 so it will grow to appropriate size
    } 
  }

  .secondary {
    display: block;
    flex: 1 1 auto;
    line-height: 0;

    @media(min-width: $medium-breakpoint) {
      flex: 1 1 0px;
    } 
  }
}
