.tooltipster-sidetip .tooltipster-box {
  border-radius: 0 !important;
  border-color: $near-black !important;
	background-color: $near-black !important;
}

.tooltipster-sidetip .tooltipster-box .tooltipster-content {
	line-height: $base-line-height;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-background {
	border-bottom-color: $near-black !important;
}

.tooltipster-sidetip.tooltipster-left .tooltipster-arrow-background {
	border-left-color: $near-black !important;
}

.tooltipster-sidetip.tooltipster-right .tooltipster-arrow-background {
	border-right-color: $near-black !important;
}

.tooltipster-sidetip.tooltipster-top .tooltipster-arrow-background {
	border-top-color: $near-black !important;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-border {
	border-bottom-color: $near-black !important;
}

.tooltipster-sidetip.tooltipster-left .tooltipster-arrow-border {
	border-left-color: $near-black !important;
}

.tooltipster-sidetip.tooltipster-right .tooltipster-arrow-border {
	border-right-color: $near-black !important;
}

.tooltipster-sidetip.tooltipster-top .tooltipster-arrow-border {
	border-top-color: $near-black !important;
}
