.customer-quote-section {
  align-items: center;
  display: flex;
  padding: $base-section-padding 0;

  /* TODO: Rewrite and remove this override - should not be tied to .customer-quote-section */
  &.neo-quote-section {
    padding: 0;

    blockquote {
      color: $base-font-color;

      footer {
        color: $light-font-color;
      }
    }
  }

  .customer-quote-slide {
    @include base-container;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0;
    bottom: 0;
  }

  blockquote {
    border: none;
    color: $alt-font-color;
    font-size: $larger-font-size;
    margin: 0;
    padding: 0 $base-gutter;
    text-align: center;
    text-shadow: none;

    @media(min-width: $medium-breakpoint) {
      padding: 0 (calc(#{$base-gutter} + #{$floating-button-size} + #{$small-spacing}));
    }

    footer {
      color: $alt-light-font-color;
      font-size: $base-font-size;
    }
  }
}

/* White font for quotes within highlight sections */
.background-fill {
  .neo-quote-section {
    blockquote {
      color: $alt-font-color;
  
      footer {
        color: $alt-light-font-color;
      }
    }
  }
}