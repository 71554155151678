.line-clamp {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

// Employees
.line-clamp-employees {
  -webkit-line-clamp: 6;
}

// Blog
.line-clamp-blog-featured-summary {
  -webkit-line-clamp: 2;
}

.line-clamp-blog-featured-title {
  -webkit-line-clamp: 3;
}

.line-clamp-blog-summary {
  -webkit-line-clamp: 4;
}

// Leaders
.line-clamp-leader-bio {
  -webkit-line-clamp: 4;
}

// Chief Servant Leader
.line-clamp-ceo-bio {
  -webkit-line-clamp: 5;
}

// NALF magazines
.line-clamp-magazine-main-description {
  -webkit-line-clamp: 7;
}

.line-clamp-magazine-prev-description {
  -webkit-line-clamp: 4;
}