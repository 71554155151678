footer {
  .heading {  
    font-family: $base-font-family;
    font-size: $smaller-font-size;
    font-weight: $semi-bold-font-weight;
  }

  .contact-info {
    .footer-icon {
      margin-right: $small-spacing;
    }
  }

  .social-media {
    li {
      display: inline-block;
      font-size: 1.5rem;
      margin-right: $small-spacing * 1.5;

      a {
        &:hover,
        &:focus {
          .footer-icon {
            color: $alt-action-color-highlight;
          }
        }
      }
    }
  }

  li {
    margin-bottom: 0;
  }

  .sign-up-form-container {
    margin-top: $base-spacing;

    .heading {
      line-height: $base-line-height;
      margin-bottom: 0;
    }
  }
}

.footer-blocks,
.footer-blocks-dark {
  background-color: transparent;
  background-repeat: repeat-x;
  background-size: 1008px 119px;
  display: block;
  height: 119px;  // Height of image file
  margin-top: $base-spacing;
  position: relative;
  bottom: -1px;   // Move image down slightly to ensure there is no gap shown between image and footer background
}

.footer-blocks {
  background-image: url('/img/footer-blocks@2x.png');
}

.footer-blocks-dark {
  background-image: url('/img/footer-blocks-dark@2x.png');
}

.footer-icon {
  color: $alt-light-font-color;
  transition: color $base-duration $base-timing;
}

.footer-content {
  background-color: $dark-gray;
  color: $alt-font-color;
  font-size: $smaller-font-size;
  line-height: $short-line-height;
  padding-top: $base-section-padding;
  padding-bottom: $base-section-padding;
}

.footer-contact-box {
  @include grid-column;

  @media(min-width: $large-breakpoint) {
    @include grid-column(4);
  }

  background: rgba(0,0,0,0.25);
  // min-height: 20em;
  padding: $base-spacing;
}

.footer-nav-column {
  @include grid-column;

  @media(min-width: $large-breakpoint) {
    @include grid-column(2);
  }
}

.footer-contact-box,
.footer-nav-column {
  margin-bottom: $base-spacing;

  li {
    margin-top: 0.5rem;

    a {
      @include alt-link-color;
      font-weight: $base-font-weight;
    }
  } 
}

.footer-bottom {
  background: darken($dark-gray, 5%);
  color: $alt-light-font-color;
  font-size: $smallest-font-size;
  padding-top: $base-section-padding;
  padding-bottom: $base-section-padding;

  a {
    color: $alt-light-font-color;

    &:hover,
    &:focus {
      color: $alt-font-color;
    }
  }
}
