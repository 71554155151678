@mixin box-base-perspective($depth) {
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
  }

  &::before {
    width: $depth;
    height: 100%;
    top: $depth;
    right: -$depth;
    transform: skewY(45deg);
    transform-origin: right;
  }
  
  &::after {
    width: 100%;
    height: $depth;
    bottom: -$depth;
    left: 0;
    transform: skewX(45deg);
    transform-origin: top;
  }
}

@mixin box-base($bg-color, $color, $perspective, $depth) {
  background: $bg-color;
  color: $color;

  @if $perspective {
    @include box-base-perspective($depth);

    &::before, 
    &::after { background: darken($bg-color, 15%); } 
  }
}

@mixin content-box($bg-color, $color, $perspective: true) {
  @include box-base($bg-color, $color, $perspective, 0.50rem);
}

@mixin image-box($bg-color, $color, $image-size, $padding) {
  @include box-base($bg-color, $color, true, 0.25rem);
  height: $image-size + 2 * $padding;
  padding: $padding;
  width: $image-size + 2 * $padding;

  img {
    width: $image-size;
    height: $image-size;
  }
}
    