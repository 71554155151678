.multiple-callout-section {
  padding-top: $base-section-padding;
  padding-bottom: $base-section-padding;

  .content {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100%;
    justify-content: center;
    padding: $base-section-padding;
    position: relative;
    width: 100%;
    z-index: 1;
  }

  .content-body,
  .content-heading {
    width: 100%;
    text-align: center;
  }

  .content-body {
    font-size: $large-font-size;
    margin-bottom: $small-spacing;

    ul, 
    ol {
      text-align: left;
    }
  }

  .content-button { 
    margin-top: $small-spacing;
  }
}

.callout-header {
  @include base-container;
  margin-bottom: $base-spacing;
  padding-left: $base-gutter;
  padding-right: $base-gutter;
  text-align: center;

  &.narrow-content {
    max-width: $narrow-container-width;
  }
}

.callout-container {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  margin: 0 auto;
  width: 100%;

  &[data-num-callouts='2'],
  &[data-num-callouts='4'] {
    @media(min-width: $medium-breakpoint) {
      flex-direction: row;
      flex-wrap: wrap;

      // In a row when 2, or stacked as a 2x2 square when 4
      .callout {
        width: 50%;
      }
    }
  } 

  &[data-num-callouts='3'] {
    @media(min-width: $extra-large-breakpoint) {
      flex-direction: row;
      flex-wrap: wrap;

      // 33% when 3 - all in a row
      // Requires more width which is why this only expands at extra-large-breakpoint
      .callout {
        width: 33.33333%;
      }
    }
  }
}

.callout {
  display: block;
  flex: 0 0 auto;
  position: relative;
  width: 100%;

  &.blue,
  &.green,
  &.orange,
  &.purple,
  &.red,
  &.teal {
    .content {
      color: $alt-font-color;
      text-shadow: $base-text-shadow;
      
      a {
        @include alt-link-color;
      }
    }

    &:before {  
      content: '';
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      z-index: 0;
    }
  }

  &.blue { 
    &:before { background-color: $blue; } 
    
    .content-button {
      @include button-color-transparent-plain($blue)
    }
  }

  &.green { 
    &:before { background-color: $green; } 
    
    .content-button {
      @include button-color-transparent-plain($green)
    }
  }

  &.orange { 
    &:before { background-color: $orange; } 
    
    .content-button {
      @include button-color-transparent-plain($orange)
    }
  }

  &.purple { 
    &:before { background-color: $purple; } 
    
    .content-button {
      @include button-color-transparent-plain($purple)
    }
  }

  &.red { 
    &:before { background-color: $red; } 
    
    .content-button {
      @include button-color-transparent-plain($red)
    }
  }

  &.teal { 
    &:before { background-color: $teal; } 
    
    .content-button {
      @include button-color-transparent-plain($teal)
    }
  }
}