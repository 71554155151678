.leader-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media(min-width: $small-breakpoint) {
    flex-direction: row;
    align-items: flex-start;
  }
}

.leader-thumbnail-wrapper {
  flex: 0 0 auto; 
  margin-bottom: $small-spacing;
  text-align: left;
  width: 188px;

  @media(min-width: $small-breakpoint) {
    margin-right: $base-spacing;
    margin-bottom: 0;
    text-align: center;
  }
}

.leader-details {
  flex: 1 1 100%;
}

.leader-mobile-heading {  
  @media(min-width: $small-breakpoint) {
    display: none;
  }
}

.leader-desktop-heading {
  display: none;

  @media(min-width: $small-breakpoint) {
    display: block;
  }
}