.analytics-metric {
  margin: 0 5px $small-spacing;
  text-align: center;
}

.analytics-heading {
  @extend dt;
  color: $light-font-color;
}

.analytics-value {
  @extend dd;
  font-size: $large-font-size;
}

.analytics-filters {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -0.25rem;
}

.analytics-date-select {
  flex: 1 1 25%;
  margin: 0 0.25rem;
  min-width: 200px;
}

.analytics-date-field {
  margin: 0 0.25rem;
  flex: 1 1 25%;
}

.analytics-date-submit {
  flex: 1 1 25%;
  margin: 0 0.25rem;
  max-width: 150px;
}