ul,
ol {
  margin: 0;
  padding: 0;
  list-style-type: none;

  &%default-ul {
    list-style-type: none;
    margin-bottom: $small-spacing;
    padding-left: $base-spacing * 2;

    // Add block icons for bullets    
    > li {
      position: relative;

      &:before {
        content: "\e908"; // Reference fontello edsi-icons icon unicode
        font-family: "edsi-icons";
        font-size: 12px;
        position: absolute;
        left: -21px;
        top: 4px;
      }
    }
  }

  &%default-ol {
    list-style-type: decimal;
    margin-bottom: $small-spacing;
    padding-left: $base-spacing * 2;
  }

  &.two-columns {
    @media (min-width: $large-breakpoint) {
      columns: 2;
    }
  }
}

li {
  margin-bottom: 0.5rem;
}

dl {
  margin: 0;
}

dt {
  color: $light-font-color;
  font-family: $heading-font-family;
  font-weight: $bold-font-weight;
  font-size: $small-font-size;
  margin: 0;
  text-transform: uppercase;
}

dd {
  font-family: $heading-font-family;
  font-weight: $bold-font-weight;
  margin: 0 0 $small-spacing 0;
}