// Add to container, supply color to fill with background image and color
.overlay-container {
  position: relative;

  &.blue,
  &.green,
  &.orange,
  &.red,
  &.teal,
  &.purple {
    color: $alt-font-color;
    text-shadow: $base-text-shadow;

    a { 
      @include alt-link-color;
    }

    blockquote {
      border-color: $alt-font-color;
      
      p {
        color: $alt-font-color;
      }

      footer {
        color: $alt-light-font-color
      }
    }

    figcaption {
      color: $alt-font-color;
      text-shadow: $base-text-shadow;
    }

    .inline-icon {
      color: $alt-light-font-color;
      text-shadow: $base-text-shadow;
    }

    // Include block pattern in colored sections
    &:before {
      background-image: linear-gradient(to right bottom, #000, rgba(#000, 0.5) 50%, rgba(#fff, 0.75));
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0.20;
    }

    &:after {
      background-image: url("/img/blocks-bg.png");
      background-position: center center;
      background-repeat: repeat;
      background-size: 320px;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0.08;
    }
  }

  &.blue { 
    background-color: $blue; 
    
    .button {
      @include button-color-transparent-plain($blue)
    }
  }

  &.green { 
    background-color: $green; 
    
    .button {
      @include button-color-transparent-plain($green)
    }
  }

  &.orange { 
    background-color: $orange; 
    
    .button {
      @include button-color-transparent-plain($orange)
    }
  }

  &.red { 
    background-color: $red; 
    
    .button {
      @include button-color-transparent-plain($red)
    }
  }

  &.teal { 
    background-color: $teal; 
    
    .button {
      @include button-color-transparent-plain($teal)
    }
  }

  &.purple { 
    background-color: $purple; 
    
    .button {
      @include button-color-transparent-plain($purple)
    }
  }
}