.blog-hero-section {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  width: 100%;

  &:before {
    @include base-overlay;
  }
}

// Align blog heading to bottom of heading image with consistent height
.blog-entry-heading-wrapper {
  $padding-top: 3rem;
  align-items: flex-end;
  display: flex;
  min-height: 21rem;
  padding-top: 6rem; // fallback approx.
  padding-top: calc(#{$header-height} + #{$padding-top});
  padding-bottom: $base-spacing;
}

.blog-title,
.blog-categories {
  margin-bottom: 0.5rem;
}

.blog-author {
  font-weight: $semi-bold-font-weight
}

.blog-category {
  @include alt-link-color;
}
