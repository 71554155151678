.slider {
  display: block;
  max-width: 100%;
  overflow-x: hidden;
  padding-top: 6px;
  padding-bottom: 6px; // A little extra padding so shadows on hover don't get cut off
  width: 100vw;

  &.slider-small-slides {
    margin: 0 auto;
    width: 100%;
    max-width: 100%;

    @media(min-width: $medium-breakpoint) {
      max-width: $max-container-width;
    }
  }

  .slide-holder {
    align-items: stretch;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    left: 0;
    position: relative;
    transition: left $slide-duration $base-timing;
    width: 100%; // Required to override inline style

    // Set width of slide holder for large slide items (half and half, quotes)
    &[data-num-slides="2"] {
      width: calc(100% * 2);
    }
    &[data-num-slides="3"] {
      width: calc(100% * 3);
    }
    &[data-num-slides="4"] {
      width: calc(100% * 4);
    }
    &[data-num-slides="5"] {
      width: calc(100% * 5);
    }
    &[data-num-slides="6"] {
      width: calc(100% * 6);
    }
    &[data-num-slides="7"] {
      width: calc(100% * 7);
    }
    &[data-num-slides="8"] {
      width: calc(100% * 8);
    }
    &[data-num-slides="9"] {
      width: calc(100% * 9);
    }
    &[data-num-slides="10"] {
      width: calc(100% * 10);
    }
    &[data-num-slides="11"] {
      width: calc(100% * 11);
    }
    &[data-num-slides="12"] {
      width: calc(100% * 12);
    }

    // Set width of slide holder for small slide items on large screens (blog posts, videos)
    @media(min-width: $medium-breakpoint) {
      &.small-slides {
        &[data-num-slides="2"] {
          width: 100%;
        }
        &[data-num-slides="3"] {
          width: 100%;
        }
        &[data-num-slides="4"] {
          width: calc(100% * 4 / 3);
        }
        &[data-num-slides="5"] {
          width: calc(100% * 5 / 3);
        }
        &[data-num-slides="6"] {
          width: calc(100% * 6 / 3);
        }
        &[data-num-slides="7"] {
          width: calc(100% * 7 / 3);
        }
        &[data-num-slides="8"] {
          width: calc(100% * 8 / 3);
        }
        &[data-num-slides="9"] {
          width: calc(100% * 9 / 3);
        }
        &[data-num-slides="10"] {
          width: calc(100% * 10 / 3);
        }
        &[data-num-slides="11"] {
          width: calc(100% * 11 / 3);
        }
        &[data-num-slides="12"] {
          width: calc(100% * 12 / 3);
        }
      }
    }
  }

  .slide {
    float: left;
    width: 100%;
  }
}
