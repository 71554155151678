/* LAYOUT */
.freeform-form {
  // font-family: sans-serif;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  &:after {
    content: "";
    clear: both;
    display: block;
  }
}

[class*="freeform-col-"] {
  float: left;
  padding: 15px;
}

/* BUTTONS */
.freeform-form {
  [data-freeform-controls] {
    margin-top: 15px;
  }

  // button {
  //   font: 400 18px sans-serif;
  //   line-height: 1.5;
  //   color: #ffffff;
  //   text-align: center;
  //   text-decoration: none;
  //   border: 1px solid #0d6efd;
  //   border-radius: 5px;
  //   background-color: #0d6efd;
  //   padding: 5px 15px;
  //   margin: 0 10px !important;
  //   cursor: pointer;
  //   display: inline-block;
  //   vertical-align: middle;
  //   transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  //     border-color 0.15s ease-in-out;
  //   justify-content: center;
  // }

  button:only-child,
  button:last-child {
    margin-right: 0;
  }

  // button:hover {
  //   color: #ffffff;
  //   background-color: #0b5ed7;
  //   border-color: #0a58ca;
  // }

  // button[data-freeform-action="back"] {
  //   color: #6c757d;
  //   background-color: transparent;
  //   border-color: #6c757d;
  // }

  // button[data-freeform-action="back"]:hover {
  //   color: #ffffff;
  //   background-color: #6c757d;
  //   border-color: #6c757d;
  // }

  button[type="submit"].freeform-processing {
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
    
    &:before {
      content: "";
      display: block;
      flex: 1 0 11px;
      width: 11px;
      height: 11px;
      margin-right: 10px;
      border-style: solid;
      border-width: 2px;
      border-color: transparent transparent #fff #fff;
      border-radius: 50%;
      animation: freeform-processing 0.5s linear infinite;
    }
  }

  button:disabled {
    color: #ffffff;
    background-color: $action-color;
    border-color: $action-color;
    cursor: not-allowed;
  }
}

@keyframes freeform-processing {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(1turn);
  }
}

/* PAGE TABS */
.freeform-pages {
  display: flex;
  padding: 0;
  margin: 0 0 25px 0;
  list-style: none;
  border-bottom: 2px solid $base-border-color;
  padding-left: 0 !important;
  
  li {
    color: $light-font-color;
    background: transparent;
    padding: 10px 20px;
    margin: 0;

    &:before {
      content: none !important;
    }

    &.active {
      color: $base-font-color;
      font-weight: bold;
      background: $light-gray;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }

  @media (max-width: $small-breakpoint) {
    display: inline-block;
  }
}

/* FORM LAYOUT */
.freeform-row {
  // font: normal 16px sans-serif;
  // color: #464747;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  margin-left: -10px;
  margin-right: -10px;


  [class*="freeform-col-"] {
    flex: 1 0;
    padding: 10px;
    margin: 0;
    box-sizing: border-box;

    > .freeform-row:first-child {
      margin-top: -10px;
    }

    label {
      display: block;
      margin: 0 0 5px 0;
    }

    // &.freeform-fieldtype-group label.group-label {
    //   font-size: 18px;
    //   font-weight: 600;
    // }

    &.freeform-fieldtype-group > .freeform-row {
      margin: -10px;
    }
    
    .freeform-input[type="checkbox"],
    .freeform-input[type="radio"] {
      margin-right: 10px;
      width: auto;
      display: inline;
      transform: scale(1.15);
    }

    label:has(> .freeform-input[type="checkbox"].freeform-has-errors),
    label:has(> .freeform-input[type="radio"].freeform-has-errors),
    &.freeform-fieldtype-checkboxes:has(ul.freeform-errors) label:not(:first-child),
    &.freeform-fieldtype-checkboxes:has(ul.freeform-errors) div.input-group-one-line label,
    &.freeform-fieldtype-radios:has(ul.freeform-errors) label:not(:first-child),
    &.freeform-fieldtype-radios:has(ul.freeform-errors) div.input-group-one-line label {
      color: $red !important;
    }

    .input-group-one-line {
      display: flex;
      flex-wrap: wrap;
    }

    .input-group-one-line label {
      padding-right: 15px;

      input[type="radio"],
      input[type="checkbox"] {
        margin-right: 10px;
        display: inline-block;
        transform: scale(1.15);
      }
    }    

    .freeform-label {
      // font-weight: 400;
      
      &.freeform-required:after {
        content: "*";
        margin-left: 5px;
        color: $red;
      }
    }
  }

  &.freeform-payments {
    margin: -10px;
  }
}

/* BASIC FIELD STYLES */
.freeform-row [class*="freeform-col-"] {
  input,
  textarea,
  select {
    box-sizing: border-box;
    // font: normal 16px sans-serif;
    color: $base-font-color;
    background-color: $secondary-background-color;
    border: $base-border;
    border-radius: $base-border-radius;
    padding: 10px;
    width: 100%;
    display: block;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }

  select:not(select[multiple]) {
    background-image: linear-gradient(45deg, transparent 50%, #6c757d 50%),
      linear-gradient(135deg, #6c757d 50%, transparent 50%),
      linear-gradient(to right, #6c757d, #6c757d);
    background-position: calc(100% - 20px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 2.5em;
  }

  input:focus,
  textarea:focus,
  select:focus {
    border: 1px solid $action-color;
    outline: 0;
    transition: border-color 0.15s ease-in-out;
  }

  input[readonly],
  textarea[readonly],
  select[readonly] {
    background-color: shade($secondary-background-color, 5%);
    border: 1px solid shade($base-border-color, 5%);
    box-shadow: none;

    &:hover,
    &:focus {
      border: 1px solid shade($base-border-color, 5%);
    }
  }

  input::placeholder,
  textarea::placeholder {
    // font: normal 16px sans-serif;
    color: $light-font-color;
  }

  input.freeform-has-errors,
  textarea.freeform-has-errors,
  select.freeform-has-errors,
  input:has(+ ul.freeform-errors),
  textarea:has(+ ul.freeform-errors),
  select:has(+ ul.freeform-errors) {
    border: 1px solid $red;
  }

  .freeform-input-only-label {
    font-weight: normal;
  }

  .freeform-input-only-label > .freeform-input {
    display: inline-block;
    width: auto;
    margin-right: 10px;
  }

  .freeform-errors {
    font-weight: $semi-bold-font-weight;
    margin: -5px 0 0 0;
    padding: 0;
    list-style: none;
    display: block;
  }

  &.freeform-fieldtype-radios .freeform-errors,
  &.freeform-fieldtype-checkboxes .freeform-errors {
    margin-top: 0;
  }

  .freeform-errors > li {
    color: $red;
    margin-top: 10px;
    margin-bottom: 0 !important;
    padding-left: 0 !important;

    &:before {
      content: none !important;
    }

    &:not(:first-child) {
      margin-top: 3px;
    }
  }

  .freeform-instructions {
    font-size: $small-font-size;
    color: $light-font-color;
    margin: -5px 0 10px 0;
  }
}

/* SINGLE CHECKBOX FIELDS */
.freeform-row [class*="freeform-col-"].freeform-fieldtype-checkbox {
  label {
    display: inline;
    margin: 0 0 10px 0;
  }
  input[type="checkbox"] {
    margin-right: 10px;
    width: auto;
    display: inline-block;
    transform: scale(1.15);
  }
  input.freeform-has-errors + label,
  label:has(+ ul.freeform-errors),
  label:has(+ div + ul.freeform-errors) {
    color: $red !important;
  }  
}

/* OPINION SCALE FIELDS */
.freeform-fieldtype-opinion-scale {
  .opinion-scale-scales {
    border: $base-border;
    border-radius: $base-border-radius;
  }

  .opinion-scale-scales li label {
    color: $dark-gray !important;
    background-color: #ffffff;
    border: 0;
    border-left: $base-border;
    padding: 10px 15px;
    margin: 0;
    cursor: pointer;
  }

  .opinion-scale-scales li:first-child label {
    border: 0;
    border-left: 0;
    border-top-left-radius: $base-border-radius;
    border-bottom-left-radius: $base-border-radius;
  }

  .opinion-scale-scales li label:hover {
    background-color: #eaeaea;
  }

  scale input[type="radio"]:checked + label {
    background-color: #cbced0 !important;
  }

  .opinion-scale-scales:has(> li input.freeform-has-errors),
  .opinion-scale:has(+ ul.freeform-errors) .opinion-scale-scales {
    border: 1px dashed $red;
  }

  .opinion-scale-legends li {
    // font: normal 15px sans-serif;
    color: $light-font-color;
    padding: 10px 0;
    margin-top: 0;
  }
}

/* RATING FIELDS */
.freeform-fieldtype-rating .freeform-has-errors + label:not(:first-child),
.freeform-fieldtype-rating div:has(+ ul.freeform-errors) label {
  color: $red;
}

.form-rating-field-wrapper input[type="radio"] + label {
  margin-right: 5px;
}

/* FILE UPLOAD DRAG & DROP FIELDS */
.freeform-file-dnd__input {
  background-color: #ffffff !important;
  border: 1px dashed $light-font-color !important;

  input {
    display: none !important;
  }
}

.freeform-file-dnd__placeholder {
  // font: italic 200 16px sans-serif !important;
  color: $light-font-color;
}

.freeform-file-dnd__input [data-messages] > li[data-error],
.freeform-file-dnd__input [data-field-errors] > li[data-error] {
  // font: 200 16px sans-serif;
  color: $red;
  margin-top: 15px;
}

.freeform-file-dnd__input [data-field-errors] > li {
  color: $red;
}

/* SIGNATURE FIELDS */
.freeform-fieldtype-signature {
  button {
    // font: 400 16px sans-serif;
    color: $light-font-color;
    border: 1px solid $light-font-color;
    background-color: transparent;

    &:hover {
      color: #fff;
      background-color: $light-font-color;
      border-color: $light-font-color;
    }
  }

  canvas {
    border: 1px solid $light-font-color;
    border-radius: $base-border-radius;
  }
}

/* TABLE FIELDS */
.freeform-fieldtype-table {
  table {
    width: 100%;
    // font: 400 14px sans-serif;
    text-align: left;

    thead tr th {
      padding-bottom: 10px;
    }
  
    tbody tr:only-child button {
      display: none;
    }
  
    tbody tr td {
      padding: 0 10px 10px 0;

      &:last-child {
        text-align: right;
        padding-right: 0;
      }
    }
  
    button[data-freeform-table-remove-row] {
      font-size: 1px;
      margin: 0;
      padding: 0;
      visibility: hidden;

      &:after {
        // font: 400 14px sans-serif;
        color: $red;
        line-height: 1.5;
        content: "✕";
        border: 1px solid $red;
        border-radius: $base-border-radius;
        padding: 5px 15px;
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
        visibility: visible;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
          border-color 0.15s ease-in-out;
      }

      &:hover:after {
        color: #ffffff;
        background-color: $red;
        border-color: $red;
      }
    }
  }

  button[data-freeform-table-add-row] {
    // font: 400 14px sans-serif;
    line-height: 1.5;
    color: $green;
    content: "+";
    border: 1px solid $green;
    background-color: transparent;
    margin: 0;

    &:hover {
      color: #ffffff;
      background-color: $green;
      border-color: $green;
    }
  
    &:after {
      content: "+";
      margin-left: 5px;
    }
  }

  input[type="checkbox"] {
    display: block;
    transform: scale(1.15);
  }
  
  select {
    min-width: 200px;
  }
}

/* ERRORS AND SUCCESS */
.freeform-form-success,
.freeform-form-errors {
  border: none;
  border-radius: $base-border-radius;
  color: #ffffff;
  font-weight: $semi-bold-font-weight;
  margin-bottom: 20px;
  padding: 15px 20px;

  p {
    margin: 0;
  }

  ul {
    margin: 10px 0 0 0;
    padding: 0 0 0 25px;

    li:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}

.freeform-form-success {
  background-color: $green;
}

.freeform-form-errors {
  background-color: $red;
}

/* RESPONSIVE GRID */
@media only screen and (max-width: 800px) {
  [class*="freeform-col-"] {
    width: 100%;
  }
}

@media only screen and (min-width: 801px) {
  .freeform-col-1 {
    width: 8.33%;
  }
  .freeform-col-2 {
    width: 16.66%;
  }
  .freeform-col-3 {
    width: 25%;
  }
  .freeform-col-4 {
    width: 33.33%;
  }
  .freeform-col-5 {
    width: 41.66%;
  }
  .freeform-col-6 {
    width: 50%;
  }
  .freeform-col-7 {
    width: 58.33%;
  }
  .freeform-col-8 {
    width: 66.66%;
  }
  .freeform-col-9 {
    width: 75%;
  }
  .freeform-col-10 {
    width: 83.33%;
  }
  .freeform-col-11 {
    width: 91.66%;
  }
  .freeform-col-12 {
    width: 100%;
  }
}

// Hide reCAPTCHA badge - must include branding on site in another way
// https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed
.grecaptcha-badge { visibility: hidden; }
