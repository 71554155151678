.blog-posts-featured-wrapper {
  @include featured-wrapper;

  .post-wrapper {
    display: inline-block;
    height: 0;
    line-height: $base-line-height;
    outline: 1px solid black;
    overflow: hidden;
    padding-bottom: $sixteenNine;
    position: relative;
    width: 100%;
  }

  .post {
    padding: $small-spacing $base-gutter;
    position: absolute;
    bottom: 0;
    z-index: 1;
  }

  .title,
  .featured-summary {
    text-shadow: $base-text-shadow;
  }

  .title {
    color: $alt-font-color;
    font-size: $larger-font-size;
    margin-bottom: $small-spacing / 2;
  }

  .featured-summary {
    color: $alt-light-font-color;
    font-weight: $medium-font-weight;
  }

  .photo-bg {
    background-repeat: repeat;
    background-position: center center;
    background-size: cover;
    height: 0;
    padding-bottom: $sixteenNine;

    &:after {
      @include gradient-overlay(black);
      transition: opacity $base-duration $base-timing;
    }
  }

  .post-wrapper:focus,
  .post-wrapper:hover {
    .photo-bg:after {
      opacity: 0.75;
    }
  }
}