.case-study-box-image-wrapper {
  display: none;
  flex: 0 0 275px;
  margin-right: $base-spacing;
  line-height: 0;

  @media (min-width: $medium-breakpoint) {
    display: inline-block;
  }
}

.narrow-container {
  .case-study-box-image-wrapper {
    display: none;
  }
}

.case-study-box-content {
  flex: 1 1 auto;
}
