.half-and-half-section {
  padding-top: $base-section-padding;
  padding-bottom: $base-section-padding;

  .half-and-half-header {
    @include base-container;
    margin-bottom: $base-spacing;
    padding-left: $base-gutter;
    padding-right: $base-gutter;
    text-align: center;

    &.narrow-content {
      max-width: $narrow-container-width;
    }
  }

  .slide {
    display: flex; // For layout within slider
  }

  .half-and-half-container {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    margin: 0 auto;
    width: 100%;

    @media(min-width: $medium-breakpoint) {
      flex-direction: row;
    }
  }

  .half {
    display: block;
    flex: 1 0 auto;
    width: 100%;

    &.image-half {
      display: none;
    }
    
    @media(min-width: $large-breakpoint) {
      width: 50%;

      &.image-half {
        display: block;
      }
    }
  }

  // @media(min-width: $large-breakpoint) {
  //   flex-direction: row;
  // }

  .content-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100%;
    justify-content: center;
    position: relative;
    width: 100%;

    // @media(min-width: $large-breakpoint) {
    //   align-items: flex-end; // pull content right so that it stays within the virtual container
    // }

    &:before {
      content: '';
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      z-index: 0;
    }

    &.blue { &:before { background-color: $blue; } }
    &.green { &:before { background-color: $green; } }
    &.orange { &:before { background-color: $orange; } }
    &.purple { &:before { background-color: $purple; } }
    &.red { &:before { background-color: $red; } }
    &.teal { &:before { background-color: $teal; } }
  }

  .image-bg {
    background-repeat: repeat;
    background-position: center center;
    background-size: cover;
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: 0;

    &.with-text {
      &:after {
        background: $base-shadow-color;
        content: '';
        height: 100%;
        position: absolute;
        width: 100%;
      }
    }
  }

  .content {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100%;
    justify-content: center;
    // max-width: 600px; // fallback
    // max-width: calc(#{$max-container-width} / 2);  // keep content contrained within virtual container
    padding: $base-section-padding $base-gutter;
    width: 100%;
    z-index: 1;
  }

  .blue,
  .green,
  .purple,
  .teal,
  .orange,
  .red,
  .image-half {
    .content {
      color: $alt-font-color;
      text-shadow: $base-text-shadow;
    }
  }

  .blue { 
    .content-button {
      @include button-color-transparent-plain($blue)
    }
  }

  .green { 
    .content-button {
      @include button-color-transparent-plain($green)
    }
  }

  .purple { 
    .content-button {
      @include button-color-transparent-plain($purple)
    }
  }

  .teal { 
    .content-button {
      @include button-color-transparent-plain($teal)
    }
  }

  .orange { 
    .content-button {
      @include button-color-transparent-plain($orange)
    }
  }

  .red { 
    .content-button {
      @include button-color-transparent-plain($red)
    }
  }

  .content-body,
  .content-heading {
    width: 100%;
    text-align: center;
  }

  .content-body {
    font-size: $large-font-size;
    margin-bottom: $small-spacing;

    ul, 
    ol {
      text-align: left;
    }
  }

  .content-button { 
    margin-top: $small-spacing;
  }
}