.pillar-page {
  .overlay-container {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .page-heading {
    font-size: 2.5rem;

    @media(min-width: $large-breakpoint) {
      font-size: 3rem;
      max-width: 840px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .with-nav {
    .page-heading {
      @media(min-width: $large-breakpoint) {
        max-width: 620px;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  .page-subheading {
    font-family: $heading-font-family;
    font-size: 1.75rem;
    font-weight: $bold-font-weight;

    @media(min-width: $large-breakpoint) {
      font-size: 2rem;
    }
  }
}

.block-mask-top,
.block-mask-bottom {
  background-color: transparent;
  background-repeat: repeat-x;
  background-size: 1008px $block-mask-height;
  display: block;
  height: $block-mask-height;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.block-mask-top {
  background-image: url("/img/masks/block-mask-top@2x.png");
  top: -1px; // Ensure there is no gap between this mask and the background
}

.block-mask-bottom {
  background-image: url("/img/masks/block-mask-bottom@2x.png");
  bottom: -1px; // Ensure there is no gap between this mask and the background
}

.hero-section-wrapper {
  margin-bottom: $base-section-padding;
}

.sticky-footer {
  background: $secondary-background-color;
  box-shadow: 0rem -0.25rem 0.25rem $light-shadow-color;
  display: block;
  font-size: $small-font-size;
  padding-top: $small-spacing / 2;
  padding-bottom: $small-spacing / 2;
  position: fixed;
  bottom: -80px;
  width: 100%;
  z-index: 21; // Submenu is 20, make sure this footer is above that
  transition: bottom $base-duration $base-timing;

  &.show {
    bottom: 0;
  }
}

// Give padding to the heading
.background-heading-wrapper {
  padding-top: $base-spacing;
  padding-bottom: $base-spacing;
}

.background-heading {
  color: white;
  display: inline-block;
  padding-left: $small-spacing;
  padding-right: $small-spacing;
  padding-top: 13px; // Vertical padding needs to be pixel perfect for block image to align properly
  padding-bottom: 13px;
  position: relative;
  margin-left: -250px;
  padding-left: 250px;

  /*
  ** Below ripped from .overlay-container
  */

  &:before {
    background-image: linear-gradient(to right bottom, #000, rgba(#000, 0.5) 50%, rgba(#fff, 0.75));
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.2;
  }

  &:after {
    background-image: url("/img/masks/block-mask-left@2x.png");
    background-position: bottom center;
    background-repeat: repeat-y;
    background-size: $block-mask-height 1008px;
    width: $block-mask-height;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
  }

  &.blue {
    background-color: $blue;
  }

  &.green {
    background-color: $green;
  }

  &.orange {
    background-color: $orange;
  }

  &.red {
    background-color: $red;
  }

  &.teal {
    background-color: $teal;
  }

  &.purple {
    background-color: $purple;
  }

  h2 {
    line-height: 36px; // Should be multiple of 9px to align well with block image
    margin: 0;
    position: relative;
    text-shadow: $base-text-shadow;
    z-index: 2;
  }
}

.flex-vertical-center {
  display: flex;
  align-items: center;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.full-screen {
  padding-top: calc(#{$header-height} + 2.75 * #{$base-spacing});
  padding-bottom: calc(#{$block-mask-height} + 2.75 * #{$base-spacing});

  @media (min-width: $large-breakpoint) {
    min-height: 680px; // Arbitrary amount based on feel for header sections with less content
    padding-top: calc(#{$header-height} + 3.25 * #{$base-spacing});
    padding-bottom: calc(#{$block-mask-height} + 3.25 * #{$base-spacing});
  }
}

.blend-image-background {
  background-blend-mode: multiply;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  &:after {
    content: "";
    opacity: 0.25;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.no-backgroundblendmode {
  .blend-image-background {
    &:before {
      content: "";
      background: #000000;
      opacity: 0.25;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
    &:after {
      opacity: 0.75;
    }
  }
}
