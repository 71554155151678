// Overrides for tingle modal windows

.tingle-enabled {
  .tingle-modal {
    .tingle-modal-box {
      border-radius: $base-border-radius;
    }
    
    .tingle-modal__close {
      background: transparent;
      color: $alt-light-font-color;

      &:hover,
      &:focus {
        color: $alt-font-color;
      }

      // Add padding to close button on large screens to make it easier to click
      @media (min-width: 541px) {
        padding: 2rem;
        width: 6rem;
        height: 6rem;
        top: 0.5rem;
        right: 0.5rem;
      }
    }

    .tingle-modal-box {
      @media (min-width: 541px) {
        max-width: 1260px;
        width: 80%;
      }
    }

    @media (max-width: 540px) {
      .tingle-modal-box__content {
        overflow-y: auto;
      }
    }

    &.transparent-modal-bg {
      .tingle-modal-box {
        background: transparent;
      }
    }
  }

  @media (max-width: 540px) {
    .tingle-modal--noOverlayClose {
      // Tingle doesn't including padding when it should
      // It should remove padding when the 'button' close method isn't enabled (since there is no longer 
      // a need to add 60px to make room for the close button). Instead it removes the padding when 
      // 'overlay' isn't enabled. Luckily we can simply override this everytime since we always use 'button'
      padding-top: 60px; 
    }
  }
}
