.flipbook-magazine-container {
  height: 780px; // Match largest max-height
  margin: 0;
  padding: 0;
  max-height: 400px; // Height at smallest breakpoint

  @media (min-width: $small-breakpoint) {
    max-height: 480px;
  }
  @media (min-width: $medium-breakpoint) {
    max-height: 620px;
  }
  @media (min-width: $large-breakpoint) {
    max-height: 720px;
  }
  @media (min-width: $extra-large-breakpoint) {
    max-height: 780px;
  }
}

.flipbook-magazine-container-landscape {
  height: 500px; // 
  margin: 0;
  padding: 0;
  max-height: 260px;

  @media (min-width: $small-breakpoint) {
    max-height: 340px;
  }
  @media (min-width: $medium-breakpoint) {
    max-height: 405px;
  }
  @media (min-width: $large-breakpoint) {
    max-height: 475px;
  }
  @media (min-width: $extra-large-breakpoint) {
    max-height: 500px;
  }
}