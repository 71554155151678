// Override questionable fontello styles 

[class^="icon-"]:before, [class*=" icon-"]:before {
  margin-left: 0;
  margin-right: 0;
  width: auto;
}

// Loading icon 

@keyframes loading-blocks-animation {
  0% {
    background: $secondary-background-color;
  }
  25% {
    background: $secondary-background-color;
  }
  25.25% {
    background: $action-color;
  }
  100% {
    background: $action-color;
  }
}

@keyframes loading-blocks-hover-animation {
  0% {
    background: $action-color;
  }
  25% {
    background: $action-color;
  }
  25.25% {
    background: $secondary-background-color;
  }
  100% {
    background: $secondary-background-color;
  }
}

.button {
  $block-border: 1px;
  $block-margin: 2px;
  $block-width: 6px;
  $block-offset: $block-width + $block-margin;
  $total-width: $block-offset + $block-width;

  .loading-blocks {
    display: inline-block;
    height: $total-width;
    margin-right: 1px;
    position: relative;
    top: 1px;
    width: $total-width;
  
    div {
      background: $action-color;
      border: $block-border solid $action-color;
      height: $block-width;
      position: absolute;
      width: $block-width;
      transition: background $base-duration $base-timing;
      animation: loading-blocks-animation 0.6s linear infinite;

      &.upper-left {
        animation-delay: 0s;
        top: 0px; left: 0px;
      }
      &.upper-right {
        animation-delay: 0.15s;
        top: 0px; left: $block-offset;
      }
      &.lower-right {
        animation-delay: 0.30s;
        top: $block-offset; left: $block-offset;
      }
      &.lower-left {
        animation-delay: 0.45s;
        top: $block-offset; left: 0px;
      }
    }
  }

  &:hover,
  &:focus {
    .loading-blocks {
      div {
        background: $secondary-background-color;
        border: $block-border solid $secondary-background-color;
        animation: loading-blocks-hover-animation 0.6s linear infinite;

        &.upper-left { animation-delay: 0s; }
        &.upper-right { animation-delay: 0.15s; }
        &.lower-right { animation-delay: 0.30s; }
        &.lower-left { animation-delay: 0.45s; }
      }
    }
  }
}

.loading-icon {
  visibility: hidden;

  &.active {
    visibility: visible;
  }
}