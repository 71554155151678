@mixin asset-section {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  width: 100%;

  .section-content {
    @include base-container;
    padding-left: $base-gutter;
    padding-right: $base-gutter;
    padding-top: $base-section-padding;
    padding-bottom: $base-section-padding;

    &.narrow-content {
      max-width: $narrow-container-width;
    }
  }

  .subheading,
  .description {
    display: inline-block;

    ul {
      @extend %default-ul;
    }
    
    ol {
      @extend %default-ol;
    }

    ul,
    ol {
      text-align: left;
    }
  }

  &.with-background {
    &:before {
      @include base-overlay;
    }

    color: $alt-font-color;
    text-shadow: $base-text-shadow;

    figcaption {
      color: $alt-font-color;
      text-shadow: $base-text-shadow;
    }

    .inline-icon {
      color: $alt-light-font-color;
      text-shadow: $base-text-shadow;
    }

    .text-wrapper {
      a {
        @include alt-link-color;
      }
    }
  }

  &.with-asset {
    // Asset left, right, and center all behave the same on small screens
    // On large screens the styles change
    &.asset-center,
    &.asset-left,
    &.asset-right {
      .heading {
        margin-bottom: $base-spacing;
      }

      .asset-wrapper {
        margin-top: 2 * $base-spacing;
        margin-left: auto;
        margin-right: auto;
        max-width: 600px;
        width: 100%;
      }

      .section-content {
        align-content: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      &.text-align-left {
        .asset-wrapper {
          margin-left: 0;
        }
      }
    }    
    
    &.asset-left,
    &.asset-right { 
      @media(min-width: $large-breakpoint) {
        height: auto;
        text-align: left;

        figcaption {
          text-align: center;
        }

        .heading {
          margin-bottom: $small-spacing;
        }
  
        .text-wrapper {
          display: flex;
          flex-direction: column;
          flex: 0 1 auto;
          justify-content: center;
          width: 100%;
        }

        .asset-wrapper {
          align-items: center;
          flex: 0 0 420px;
          margin-top: 0;
        }
  
        .section-content {
          align-items: center;
          flex-direction: row;
          height: auto;
        }
      }
    }

    &.asset-left { 
      @media(min-width: $large-breakpoint) {
        .text-wrapper {
          margin-left: 0;
          margin-right: auto;
          order: 1;
        }

        .asset-wrapper {
          margin-left: 0;
          margin-right: 2 * $base-spacing;
          order: 0;
        }
      }
    }

    &.asset-right {
      @media(min-width: $large-breakpoint) {
        .text-wrapper {
          margin-left: 0;
          margin-right: 2 * $base-spacing;
          order: 0;
        }

        .asset-wrapper {
          margin-right: 0;
          order: 1;
        }
      }
    }
  }

  &.without-asset {
    text-align: left;
  }
}