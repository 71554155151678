.social-share-icons-sticky {
  display: none;
  flex-direction: column;
  float: left;
  height: 5px; // Limit height and set overflow:visible so that it doesn't interrupt the
               // flow of other elements
  margin-bottom: $base-section-padding;
  margin-left: $base-gutter;
  overflow: visible;
  position: sticky;
  transition: opacity $base-duration $base-timing;
  top: 96px;  // fallback approximation
  top: calc(#{$header-height} + #{$base-section-padding});
  z-index: 3;

  @media(min-width: $large-breakpoint) {
    display: flex;
  }

  &.hidden {
    opacity: 0;
  }
}

.social-share-icons-blog-post {
  margin-top: $base-section-padding;
}

.social-share-heading {
  color: rgba(0,0,0,0.5);
  margin-bottom: 8px;
  text-align: center;
  font-size: 14px;
  font-weight: $semi-bold-font-weight;
  overflow: visible;
  width: $floating-button-size;  // Should match .social-share-icon width
  z-index: 1;
}

.social-share-icon {
  align-items: center;
  background: $secondary-background-color;
  backdrop-filter: blur(4px);
  box-shadow: $light-floating-box-shadow;
  color: black;
  display: flex;
  flex: 0 0 auto;
  font-size: $larger-font-size;
  height: $floating-button-size;
  justify-content: center;
  margin-bottom: 8px;
  opacity: 0.5;
  padding: 12px;
  transition: opacity $base-duration $base-timing,
    color $base-duration $base-timing; 
  width: $floating-button-size;
  z-index: 1;

  &:hover, &:focus {
    opacity: 1;
  }
}

.facebook-icon {
  &:hover, &:focus {
    color: #3b5998;
  }
}
.twitter-icon {
  &:hover, &:focus {
    color: #1da1f2;
  }
}

.linkedin-icon {
  &:hover, &:focus {
    color: #0077b5;
  }
}

.instagram-icon {
  font-size: 1.375rem;
  
  &:hover, &:focus {
    color: #f56040
  }
}

.email-icon {
  &:hover, &:focus {
    color: $action-color;
  }
}