// Basically a rewrite/simplification of .overlay-container
.background-fill {
  color: $alt-font-color;
  text-shadow: $base-text-shadow;

  .base-subheading {
    color: $alt-light-font-color;
  }
  
  a {
    @include alt-link-color;
  }

  blockquote {
    border-color: $alt-font-color;
    
    p {
      color: $alt-font-color;
    }

    footer {
      color: $alt-light-font-color
    }
  }

  figcaption {
    color: $alt-font-color;
    text-shadow: $base-text-shadow;
  }
}

// :after background-color is set in case .blend-image-background is set as well
.background-fill-blue {
  background-color: $blue;
  &:after { 
    background-color: $blue; 
  }
}
.background-fill-green {
  background-color: $green;
  &:after { 
    background-color: $green; 
  }
}
.background-fill-orange {
  background-color: $orange;
  &:after { 
    background-color: $orange; 
  }
}
.background-fill-purple {
  background-color: $purple;
  &:after { 
    background-color: $purple; 
  }
}
.background-fill-red {
  background-color: $red;
  &:after { 
    background-color: $red; 
  }
}
.background-fill-teal {
  background-color: $teal;
  &:after { 
    background-color: $teal; 
  }
}
.background-fill-darken {
  background-color: $base-shadow-color;
}

.background-fill-gradient {
  position: relative;

  &:before {
    background-image: linear-gradient(to right bottom, #000, rgba(#000, 0.5) 50%, rgba(#fff, 0.75));
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.24;
  }
}

.background-fill-blocks {
  position: relative;

  &:after {
    background-image: url("/img/blocks-bg.png");
    background-position: center center;
    background-repeat: repeat;
    background-size: 320px;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.08;
  }
}