.career-pathways-templates {
  @include base-container;
  
  .career-pathways-header {
    @include grid-column;
    padding-bottom: $base-spacing;
    text-align: center;
  }

  .template {
    @include box-base($secondary-background-color, $base-font-color,
      true, 0.25rem);
    display: inline-block;
    position: relative;

    .template-overlay {
      @include gradient-overlay($light-shadow-color);
      transition: opacity $base-duration $base-timing;
    }

    &:hover,
    &:focus {
      .template-overlay {
        opacity: 0;
      }  
    }

    img {
      height: 280px;
      min-width: 200px;
    }
  }

  .template-name {
    margin-top: $small-spacing;
  }
}

.career-pathways-template-wrapper,
.career-pathways-download-wrapper {
  @include grid-column;
  margin-bottom: $base-spacing;
  text-align: center;

  @media(min-width: $small-breakpoint) {
    @include grid-column(6);
  }
}

.career-pathways-template-wrapper {
  @media(min-width: $large-breakpoint) {
    @include grid-column(3);
  }
}

.career-pathways-buttons-container {
  @include base-container;
  margin: $base-spacing auto;
  max-width: 1000px;

  .button-wrapper {
    @include grid-column;
    margin-bottom: $base-spacing;

    @media(min-width: $large-breakpoint) {
      @include grid-column(6);
    }
  }

  .button {
    width: 100%;

    &.accent {
      @include button-color-solid($accent-color, $accent-color-highlight);
    }
  }
}

.career-pathways-info {
  @include base-container;
  margin-top: $base-spacing;
  
  .career-pathways-column-third {
    @include grid-column;

    @media(min-width: $large-breakpoint) {
      @include grid-column(4);
    }
  }
}
