.user-image {
  @include image-box($secondary-background-color, $base-font-color, 96px, 4px);
  flex: 0 0 auto;
}

.leader-filter {
  @include grid-column;

  @media(min-width: $medium-breakpoint) {
    @include grid-column(6);
  }
}

.regional-leaders-container {
  @include base-container;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -$base-spacing;
}

.regional-leaders-column {
  @include grid-column;
  align-items: flex-start;
  display: flex;
  margin-bottom: $base-spacing;
  
  @media(min-width: $small-breakpoint) {
    @include grid-column(6);
  }

  @media(min-width: $large-breakpoint) {
    @include grid-column(4);
  }

  @media(min-width: $max-container-width) {
    @include grid-column(3);
  }
}

.regional-leader-image {
  margin-bottom: $small-spacing;
  margin-right: $small-spacing;
}
