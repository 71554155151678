.company-info-section {
  padding-top: $base-section-padding;
  padding-bottom: $base-section-padding;

  .company-info-main-column {
    @include grid-column;
  
    @media(min-width: $large-breakpoint) {
      @include grid-column(8);
    }
  }
  
  .company-info-aside-column {
    @include grid-column;
  
    @media(min-width: $large-breakpoint) {
      @include grid-column(4);
    }
  }
  
  .quick-facts {
    @include content-box($blue, $alt-font-color);
    padding: $small-spacing;
  
    a {
      @include alt-link-highlight-color;
    }
  }
}
