@mixin visibility-hidden {
  visibility: hidden !important;
  height: 0 !important;
  padding: 0 !important;
  width: 0 !important;
}

/* -- Hide element from view - For screen readers */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.light-floating-box-shadow {
  box-shadow: $light-floating-box-shadow;
}

.base-floating-box-shadow {
  box-shadow: $base-floating-box-shadow;
}

.light-floating-box-shadow-hover,
.base-floating-box-shadow-hover {
  // Include opacity and filter transitions for blur in case this element is a lazyloaded .inline-image
  transition: box-shadow $base-duration $base-timing, opacity 500ms $base-timing, filter 650ms $base-timing !important;
}

.light-floating-box-shadow-hover {
  &:hover {
    box-shadow: $light-floating-box-shadow-hover;
  }
}

.base-floating-box-shadow-hover {
  &:hover {
    box-shadow: $base-floating-box-shadow-hover;
  }
}

.position-relative {
  position: relative;
}

.position-fixed {
  position: fixed;
}

.responsive-img {
  display: block; // Make sure images don't have extra space below them
  height: auto;
  width: 100%;
}

.display-none {
  display: none !important;
}

.display-inline-block {
  display: inline-block;
}

.display-none-min-medium {
  @media(min-width: $medium-breakpoint) {
    display: none !important;
  }
}

// Add blur to inline images as they load in. Don't apply this to
// other images (background/etc) beacuse the blur causes a gap to appear
// around the edges
.inline-image.lazyload {
  filter: blur(15px);
}

.inline-image {
  transition: opacity 500ms, filter 500ms;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

// Font sizes

.smallest-font-size {
  font-size: $smallest-font-size;
}

.smaller-font-size {
  font-size: $smaller-font-size;
}

.small-font-size {
  font-size: $small-font-size;
}

.base-font-size {
  font-size: $base-font-size;
}

.large-font-size {
  font-size: $large-font-size;
}

.larger-font-size {
  font-size: $larger-font-size;
}

.largest-font-size {
  font-size: $largest-font-size;
}

// Font colors

.font-color-blue {
  color: $blue;
}

.font-color-green {
  color: $green;
}

.font-color-orange {
  color: $orange;
}

.font-color-purple {
  color: $purple;
}

.font-color-red {
  color: $red;
}

.font-color-teal {
  color: $teal;
}

.font-color-alt {
  color: $alt-font-color;
  text-shadow: $base-text-shadow;
}

.font-color-alt-light {
  color: $alt-light-font-color;
  text-shadow: $base-text-shadow;
}

.font-color-light {
  color: $light-font-color;
}

// Font styles

// Used in textCallout blocks
.font-style-bold {
  font-weight: $semi-bold-font-weight;
}

// Used in textCallout blocks
.font-style-italic {
  font-style: italic;
}

.width-100 {
  width: 100%;
}

// Flex alignment
.flex {
  display: flex;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-justify-content-start {
  justify-content: flex-start;
}

.flex-justify-content-end {
  justify-content: flex-end;
}

.flex-justify-content-center {
  justify-content: center;
}

.flex-justify-content-space-around {
  justify-content: space-around;
}

.flex-align-items-start {
  align-items: flex-start;
}

.flex-align-items-center {
  align-items: center;
}

.flex-item {
  flex: 0 1 auto;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-item-justify-start {
  margin-right: auto;
}

.flex-item-justify-end {
  margin-left: auto;
}

.flex-item-justify-center {
  margin-left: auto;
  margin-right: auto;
}

.flex-item-justify-start-column {
  margin-bottom: auto;
}

.flex-item-justify-end-column {
  margin-top: auto;
}

.flex-item-justify-center-column {
  margin-top: auto;
  margin-bottom: auto;
}

.flex-item-align-start {
  align-self: flex-start;
}

.flex-item-align-end {
  align-self: flex-end;
}

.flex-item-align-center {
  align-self: center;
}

// Spacing
.no-margin {
  margin: 0;
}

.no-margin-top {
  margin-top: 0;
}

.no-margin-right {
  margin-right: 0;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.no-margin-left {
  margin-left: 0;
}

.no-padding {
  padding: 0;
}

.no-padding-top {
  padding-top: 0;
}

.no-padding-right {
  padding-right: 0;
}

.no-padding-bottom {
  padding-bottom: 0;
}

.no-padding-left {
  padding-left: 0;
}

.small-margin {
  margin: $small-spacing;
}

.small-margin-top {
  margin-top: $small-spacing;
}

.small-margin-right {
  margin-right: $small-spacing;
}

.small-margin-bottom {
  margin-bottom: $small-spacing;
}

.small-margin-left {
  margin-left: $small-spacing;
}

.small-padding {
  padding: $small-spacing;
}

.small-padding-top {
  padding-top: $small-spacing;
}

.small-padding-right {
  padding-right: $small-spacing;
}

.small-padding-bottom {
  padding-bottom: $small-spacing;
}

.small-padding-left {
  padding-left: $small-spacing;
}

.base-margin {
  margin: $base-spacing;
}

.base-margin-top {
  margin-top: $base-spacing;
}

.base-margin-right{
  margin-right: $base-spacing;
}

.base-margin-bottom {
  margin-bottom: $base-spacing;
}

.base-margin-left {
  margin-left: $base-spacing;
}

.base-padding {
  padding: $base-spacing;
}

.base-padding-top {
  padding-top: $base-spacing;
}

.base-padding-right{
  padding-right: $base-spacing;
}

.base-padding-bottom {
  padding-bottom: $base-spacing;
}

.base-padding-left {
  padding-left: $base-spacing;
}

.large-margin {
  margin: $base-spacing * 2;
}

.large-margin-top {
  margin-top: $base-spacing * 2;
}

.large-margin-right{
  margin-right: $base-spacing * 2;
}

.large-margin-bottom {
  margin-bottom: $base-spacing * 2;
}

.large-margin-left {
  margin-left: $base-spacing * 2;
}

.large-padding {
  padding: $base-spacing * 2;
}

.large-padding-top {
  padding-top: $base-spacing * 2;
}

.large-padding-right {
  padding-right: $base-spacing * 2;
}

.large-padding-bottom {
  padding-bottom: $base-spacing * 2;
}

.large-padding-left {
  padding-left: $base-spacing * 2;
}

.base-section-padding {
  padding-top: $base-section-padding;
  padding-bottom: $base-section-padding;
}

.base-section-padding-top {
  padding-top: $base-section-padding;
}

.base-section-padding-bottom {
  padding-bottom: $base-section-padding;
}

// Display list content within with default styles
.default-lists {
  ol {
    @extend %default-ol;
  }
  ul {
    @extend %default-ul;
  }
}

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}
