$image-collection-margin: 0.75rem;

.image-collection {
  margin-left: -$image-collection-margin; 
  margin-right: -$image-collection-margin;
}

.image-collection-image-wrapper {
  cursor: pointer;
  line-height: 0;
  margin: $image-collection-margin;
}
