// Shared styles between inline videos, images, and files
// If no text-wrapper appears at the top there should be no margin-top on asset
// Adding a margin-bottom to the text wrapper will compensate and only be present
// when there is heading/description text above the asset.
.inline-asset-section {
  @include asset-section;

  .text-wrapper {
    margin-bottom: $base-spacing; // Add margin to text-wrapper
  }

  &.with-asset {
    &.asset-center,
    &.asset-left,
    &.asset-right {
      .asset-wrapper {
        margin-top: 0; // Override top margin
      }
    }
  }

  &.without-asset {
    text-align: center;
  }
}