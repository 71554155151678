@mixin button-color-solid($color, $hover-color) {
  background-color: $color !important;
  color: $alt-font-color !important;
  text-shadow: none !important;
  border-color: $color !important;

  &:hover,
  &:focus {
    background-color: $hover-color !important;
    border-color: $hover-color !important;
    color: $alt-font-color !important;
  }
}

@mixin button-color-solid-plain-bordered {
  background-color: $secondary-background-color !important;
  border: 2px solid $action-color !important;
  color: $action-color !important;
  text-shadow: none !important;

  &:hover,
  &:focus {
    background-color: $action-color !important;
    color: $alt-font-color !important;
  }
}

@mixin button-color-transparent-plain($hover-color) {
  background-color: transparent !important;
  border: 2px solid $alt-font-color !important;
  color: $alt-font-color !important;
  text-shadow: none !important;

  &:hover,
  &:focus {
    background-color: $alt-font-color !important;
    color: $hover-color !important;
  }
}

// All buttons
button,
[type="button"],
[type="reset"],
[type="submit"],
.button {
  appearance: none;
  background-color: $action-color;
  border: none;
  border-radius: $base-border-radius;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: $base-font-family;
  font-size: $base-font-size;
  font-weight: $semi-bold-font-weight;
  line-height: 1;
  padding: $small-spacing $base-spacing;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color $base-duration $base-timing,
    border-color $base-duration $base-timing,
    color $base-duration $base-timing;
  user-select: none;
  vertical-align: middle;

  &:hover,
  &:focus {
    background-color: $action-color-highlight;
    color: #fff;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:hover {
      background-color: $action-color;
    }
  }

  &.block {
    display: block;
    width: 100%;
  }
}

.button-perspective {
  @include box-base($action-color, $action-color-highlight, true, $base-button-depth);

  &:active {
    right: -$base-button-depth / 2 !important;
    top: $base-button-depth / 2 !important;
    
    &::before {
      width: $base-button-depth / 2 !important;
      right: -$base-button-depth / 2 !important;
      top: $base-button-depth / 2 !important;
    }

    &::after {
      height: $base-button-depth / 2 !important;
      bottom: -$base-button-depth / 2 !important;
    }
  }
}

.button-color-primary,
.button-color-none,
.button-color-blue {
  @include button-color-solid($blue, darken($blue, 10%));
  
  &::before, 
  &::after { background: darken($blue, 15%) !important; } 
}

.button-color-green {
  @include button-color-solid($green, darken($green, 10%));

  &::before, 
  &::after { background: darken($green, 15%) !important; } 
}

.button-color-accent,
.button-color-orange {
  @include button-color-solid($orange, darken($orange, 10%));

  &::before, 
  &::after { background: darken($orange, 15%) !important; } 
}

.button-color-purple {
  @include button-color-solid($purple, darken($purple, 10%));

  &::before, 
  &::after { background: darken($purple, 15%) !important; } 
}

.button-color-red {
  @include button-color-solid($red, darken($red, 10%));

  &::before, 
  &::after { background: darken($red, 15%) !important; } 
}

.button-color-teal {
  @include button-color-solid($teal, darken($teal, 10%));

  &::before, 
  &::after { background: darken($teal, 15%) !important; } 
}

.button-color-secondary {
  @include button-color-solid-plain-bordered;
}

.button-color-plain {
  @include button-color-solid-plain-bordered;
  border: $base-border !important;
  color: $plain-action-color !important;

  &:hover,
  &:focus {
    background-color: darken($secondary-background-color, 5%) !important;
    color: $plain-action-color !important;
  }
}

.button-color-alt,
.button-color-alt-blue {
  @include button-color-transparent-plain($blue);
}

.button-color-alt-green {
  @include button-color-transparent-plain($green);
}

.button-color-alt-orange {
  @include button-color-transparent-plain($orange);
}

.button-color-alt-purple {
  @include button-color-transparent-plain($purple);
}

.button-color-alt-red {
  @include button-color-transparent-plain($red);
}

.button-color-alt-teal {
  @include button-color-transparent-plain($teal);
}