$home-desktop-breakpoint: 710px;

.home-hero-container {
  background: $blue; 
  overflow: hidden;
  padding-top: 5.5rem;
  padding-bottom: 11.5rem;
  position: relative;

  @media(min-width: $home-desktop-breakpoint) {
    padding-bottom: 12.5rem;
  }

  @media(min-width: 900px) {
    padding-top: 7rem;
  }
}

.home-hero-background {
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;

  @media(min-width: $home-desktop-breakpoint) {
    background-position: top right;
  }
}

.home-hero-background-grid {
  background-image: url('/img/grid.png');
  background-position: 5px; // Offset to align with fading blocks image mask
  background-repeat: repeat;
  position: absolute;
  opacity: 0.10;
  mask-image: radial-gradient(transparent 0%, rgba(black, 0.30) 70%, black 100%);
  top: 0; right: 0; bottom: 0; left: 0;
}

.home-hero-content {
  align-items: flex-start;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 $base-gutter;

  @media(min-width: $home-desktop-breakpoint) {
    align-items: center;
    flex-direction: row;
    padding-right: 0;
  }
}

.home-heading {
  color: $alt-font-color;
  display: inline-block;
  font-size: 1.75rem;
  margin-bottom: 2.75rem;

  @media(min-width: $small-breakpoint) {
    font-size: 1.875rem;
  }

  @media(min-width: 745px) {
    font-size: 2rem;
    margin-bottom: 3rem;
  }

  @media(min-width: 850px) {
    font-size: 2.25rem;
    margin-bottom: 3.25rem;
  }

  @media(min-width: 930px) {
    font-size: 2.5rem;
    margin-bottom: 3.5rem;
  }
}

// Only show the heading line break on desktop
.home-heading-br {
  display: none;

  @media(min-width: $home-desktop-breakpoint) {
    display: inline;
  }
}

.heading-highlight {
  background-color: #f3ec78;
  background-image: linear-gradient(83deg, #f3ec78, #fcbd7e, #f3ec78);
  background-size: 100%;
  color: $alt-font-color;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  
  @media(min-width: $home-desktop-breakpoint) {
    display: inline-block;
  }
}

.home-hero-animation-base {
  animation-name: bg-blur, bg-move;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  animation-direction: alternate;
}

.home-hero-animation-1 {
  animation-duration: 15.1s;
}

.home-hero-animation-2 {
  animation-duration: 13.0s;
}

.home-hero-animation-3 {
  animation-duration: 9.8s;
}

.home-hero-animation-4 {
  animation-duration: 7.45s;
}

.hero-video-container {
  display: inline-block;
  
  @media(min-width: $home-desktop-breakpoint) {
    margin-right: 1.5rem;
    max-width: 560px;
  }

  @media(min-width: 800px) {
    margin-right: 3rem;
  }
}

.home-video-caption {
  color: $alt-font-color;
  font-size: $large-font-size;
  font-weight: $bold-font-weight;
  line-height: 1.5;
  margin-top: $base-spacing;
  text-shadow: $base-text-shadow;

  @media(min-width: $home-desktop-breakpoint) {
    margin-bottom: -4px; // Compensate for extra space due to line-height, to better align video vertically with the sidebar
  }
}

.home-sidebar {
  background: rgba(0,41,78,0.75);
  color: white;
  display: inline-block;
  flex: 0 0 auto;
  margin-top: 3rem;
  padding: 2rem 2rem 1.25rem 2rem;
  width: 100%;
  
  @media(min-width: $home-desktop-breakpoint) {
    margin-top: 0;
    width: 278px;
  }

  @media(min-width: 1025px) {
    width: 360px;
  }

  @media(min-width: 1100px) {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
    width: 444px;
  }
}

.home-sidebar-hr {
  margin: 0.75rem 0;
}

.home-sidebar-item {
  align-items: center;
  color: white;
  display: flex;
  font-family: $heading-font-family;
  font-size: 0.875rem;
  line-height: $heading-line-height;
  padding: 0.75rem 0;

  > svg {
    fill: $alt-action-color;
    flex: 0 0 auto;
    transition: fill $base-duration $base-timing;
  }

  &:hover,
  &:focus {
    color: white;

    > svg {
      fill: $alt-action-color-highlight;
    }
  }
}

.home-sidebar-item-icon {
  margin-right: 1rem;
  vertical-align: middle;
}

@keyframes bg-blur {
  0% {
    opacity: 0.25;
  }
  100% {
    opacity: 0.65;
  }
}

@keyframes bg-move {
  from {
    transform: translate(4px, -9px);
  }
  to {
    transform: translate(-4px, 6px);
  }
}
