.toggle-button,
.toggle-content {
  background: $secondary-background-color;
  backdrop-filter: blur(4px);
  box-shadow: $light-floating-box-shadow;
  max-width: 360px;
  position: fixed;
  transition: box-shadow $base-duration $base-timing,
    transform 500ms cubic-bezier(0.165, 0.84, 0.44, 1), // easeOutQuart
    opacity 500ms $base-timing;
  
  &.hidden {
    opacity: 0;
  }
}

.toggle-content-heading {
  margin-right: 22px; // Add space for close button
}

.toggle-content-position-center {
  bottom: 4.5rem;
  left: calc(-50vw + 50%);
  margin-left: auto;
  margin-right: auto;
  max-width: 760px;
  max-height: calc(100vh - (2 * 4.5rem));
  overflow-y: auto;
  right: calc(-50vw + 50%);

  &.hidden {
    transform: translateY(550px); // IE fallback
    transform: translateY(calc(100% + 4.5rem));
  }
}

.toggle-content-position-top-right {
  top: 88px;
  top: calc(#{$header-height} + #{$base-gutter});
  right: $base-gutter;

  &.hidden {
    transform: translateX(425px); // IE fallback
    transform: translateX(calc(100% + #{$base-gutter}));
  }
}

.toggle-content-position-bottom-right {
  bottom: $base-gutter;
  right: $base-gutter;

  &.hidden {
    transform: translateY(425px); // IE fallback
    transform: translateY(calc(100% + #{$base-gutter}));
  }
}

.toggle-content-position-bottom-left {
  bottom: $base-gutter;
  left: $base-gutter;

  &.hidden {
    transform: translateY(425px); // IE fallback
    transform: translateY(calc(100% + #{$base-gutter}));
  }
}

.toggle-footer {
  margin: -1 * $small-spacing;
  margin-top: $small-spacing;
  padding: $base-spacing;
  width: auto;
  background-color: $lightest-gray;
}

.toggle-content-position-center {
  .toggle-footer {
    margin: -1 * $base-spacing;
    margin-top: $base-spacing;
    margin-right: -2 * $base-spacing;
  }
}

.toggle-button {
  cursor: pointer;
  align-items: center;
  display: flex;
  font-weight: $semi-bold-font-weight;
  height: $floating-button-size;
  justify-content: center;
  opacity: 0.5;
  text-align: center;
  width: $floating-button-size;
  z-index: 26;  // body-overlay is 30, this should be below that

  &:hover,
  &:focus {
    box-shadow: $light-floating-box-shadow-hover;
    opacity: 1;
  
    // Stay hidden
    &.hidden {
      box-shadow: none;
      opacity: 0; 
    }
  }
}

.toggle-content {
  padding: $small-spacing;
  z-index: 25;  // just below toggle-button, so that this content can't interfere with clicking the button

  &.toggle-content-position-center {
    padding: $base-spacing;
    padding-right: 2 * $base-spacing;
    z-index: 31; // This content should appear ABOVE the body-overlay
  }
}

.toggle-close-button {
  @include light-link-color;
  cursor: pointer;
  float: right;
  margin: -$small-spacing;
  padding: $small-spacing;
  transition: color $base-duration $base-timing;
}

.toggle-content-position-center {
  .toggle-close-button {
    margin: -$base-spacing;
    margin-right: -2 * $base-spacing;
    padding: $base-spacing;
  }
}