.slider-nav-container {
  position: relative;
  z-index: 2;
  
  &:hover,
  &:focus {
    .slide-icon {
      opacity: 0.75;
  
      &:hover,
      &:focus {
        box-shadow: $light-floating-box-shadow-hover;
        opacity: 1;
      }
    }
  }
}

.slider-nav-left,
.slider-nav-right {
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0; bottom: 0;
  width: auto;
  z-index: 10;
}

.slider-nav-left {
  left: $base-gutter / 2;
}

.slider-nav-right {
  right: $base-gutter / 2;
}

.slide-icon {
  align-items: center;
  background: $secondary-background-color;
  box-shadow: $light-floating-box-shadow;
  color: $plain-action-color;
  cursor: pointer;
  display: flex;
  font-size: 1.5rem;
  height: $floating-button-size;
  justify-content: center;
  opacity: 0.25;
  text-shadow: none;
  transition: background-color $base-duration $base-timing,
    box-shadow $base-duration $base-timing,
    color $base-duration $base-timing,
    opacity $base-duration $base-timing;
  width: $floating-button-size;

  &.disabled {
    display: none;
  }
}
