@mixin base-container {
  @include grid-container;
  // @include grid-visual;
  margin-left: auto;
  margin-right: auto;
  max-width: $max-container-width;
  position: relative;
  z-index: 1;
}

// Default page content wrapper
.container {
  @include base-container;

  &.dropdown-container {
    z-index: 3;
  }
}

// Default pillar page/blog page content wrapper
.narrow-container {
  @include base-container;
  max-width: $narrow-container-width;

  p, ul, ol { 
    margin-bottom: $base-spacing;
  }

  &.expand-at-large-breakpoint {
    @media only screen and (min-width: $large-breakpoint) {
      max-width: $extra-large-breakpoint;
    }
  }
}

// Span all grid-columns
.full-width-column {
  @include grid-column;
}

.third-width-column {
  @include grid-column;

  @media (min-width: $medium-breakpoint) {
    @include grid-column(4);
  }
}

// Flex container requires flex-columns within to have proper gutters
@mixin flex-container {
  @include base-container;
  display: flex;
  flex-wrap: wrap;
  padding-left: $base-gutter / 2;
  padding-right: $base-gutter / 2;
}

@mixin flex-column($columns: $base-columns) {
  $width-percent: percentage($columns / $base-columns); 
  flex: 0 0 auto;
  margin-left: $base-gutter / 2;
  margin-right: $base-gutter / 2;
  width: calc(#{$width-percent} - #{$base-gutter});
}

body {
  background: $base-background-color;
}

// This element should go directly within a .container
.two-column-content {
  display: flex;
  flex-direction: column;

  @media (min-width: $large-breakpoint) {
    flex-direction: row;
  }
}

%column-base {
  flex: 1 1 auto;
  padding: $base-gutter; // This matches the container negative margin
  width: 100%;

  @media (min-width: $large-breakpoint) {
    padding: 0 $base-gutter;
  }
}

.column-auto {
  @extend %column-base;
}

.column-33 {
  @extend %column-base;
  flex-basis: 33%;
}

.column-50 {
  @extend %column-base;
  flex-basis: 50%;
}

.column-67 {
  @extend %column-base;
  flex-basis: 67%;
}

.two-column-left-column,
.two-column-right-column {
  flex: 1 1 auto;
  padding: $base-gutter; // This matches the container negative margin
  width: 100%;
  
  @media (min-width: $large-breakpoint) {
    padding: 0 $base-gutter;
  }
}

.align-center {
  text-align: center;
}
.align-left {
  text-align: left;
}
.align-right {
  text-align: right;
}
