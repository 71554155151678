// Ensure these styles take precedence over .overlay-container
.box,
.overlay-container .box {
  @include content-box($secondary-background-color, $base-font-color);
  margin-bottom: $base-spacing;
  padding: $base-spacing;
  text-shadow: none;

  .inline-icon {
    color: $light-font-color;
    text-shadow: none;
  }    
  
  a {
    @include base-link-color;
  }

  .heading {
    a {
      @include heading-link-color;
    }
  }

  .button {
    margin-top: $small-spacing;
  }

  &.blue,
  &.green,
  &.orange,
  &.purple,
  &.red,
  &.teal {
    text-shadow: $base-text-shadow;

    .inline-icon {
      color: $alt-light-font-color;
      text-shadow: $base-text-shadow;
    }

    a {
      @include alt-link-color;
    }
  }

  &.none { 
    .button {
      @include button-color-solid($action-color, $action-color-highlight);
    }
  }

  &.blue { 
    @include content-box($blue, $alt-font-color);
    background-color: $blue; 
    
    .button {
      @include button-color-transparent-plain($blue);
    }
  }

  &.green { 
    @include content-box($green, $alt-font-color);
    background-color: $green; 
    
    .button {
      @include button-color-transparent-plain($green);
    }
  }

  &.orange { 
    @include content-box($orange, $alt-font-color);
    background-color: $orange; 
    
    .button {
      @include button-color-transparent-plain($orange);
    }
  }

  &.purple { 
    @include content-box($purple, $alt-font-color);
    background-color: $purple; 
    
    .button {
      @include button-color-transparent-plain($purple);
    }
  }

  &.red {
    @include content-box($red, $alt-font-color); 
    background-color: $red; 
    
    .button {
      @include button-color-transparent-plain($red);
    }
  }

  &.teal { 
    @include content-box($teal, $alt-font-color);
    background-color: $teal; 
    
    .button {
      @include button-color-transparent-plain($teal);
    }
  }
}
