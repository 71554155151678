.careers-hero {
  padding-top: $base-section-padding;
  padding-bottom: calc(#{$base-section-padding} + 119px);
  text-align: center;

  .careers-buttons-container {
    @include base-container;
    margin: $base-spacing auto;
    max-width: 900px;

    .button-wrapper-full {
      @include grid-column;
      margin-bottom: $base-spacing;
    }

    .button-wrapper {
      @include grid-column;
      margin-bottom: $base-spacing;

      @media(min-width: 900px) {
        @include grid-column(6);
      }
    }

    .button {
      width: 100%;

      &.button-color-accent {
        @include button-color-solid($accent-color, $accent-color-highlight);
      }
    }
  }
}