.blog-section {
  padding-top: $base-section-padding;
  // No padding bottom because it pushes down the load more button
}

.blog-latest-posts-title {
  @include grid-column;

  @media(min-width: $medium-breakpoint) {
    @include grid-column(8);
  }
}

.blog-filter {
  @include grid-column;

  @media(min-width: $medium-breakpoint) {
    @include grid-column(4);
  }
}

.container-collapse {
  @include grid-collapse;
  @include grid-container;
}

.col10 {
  @include grid-column(10);
}

.col2 {
  @include grid-column(2);
}