.heading-block {
  flex: 1 1 auto;
  text-align: center;

  // Align left on mobile
  @media (max-width: $large-breakpoint) {
    text-align: left;
  }

  &.with-nav {
    @media only screen and (min-width: $large-breakpoint) {
      text-align: left;
    }
  }
}

.nav-block {
  flex: 0 0 auto;
  width: 300px;
}

.pillar-page ul.hero-nav-list {
  display: none;
  font-size: $small-font-size;
  margin-top: $base-spacing;
  margin-bottom: $base-spacing;
  margin-left: $base-spacing * 3;
  text-align: left;
  width: 300px;

  @media only screen and (min-width: $large-breakpoint) {
    display: block;
  }
}

.hero-nav-item,
.hero-nav-item-second-level {
  margin-bottom: 0;
}

.hero-nav-item-second-level {
  margin-left: $small-spacing;
}

.hero-nav-link {
  @include alt-link-color;
  display: block;
  font-weight: $semi-bold-font-weight;
  padding-top: $small-spacing;
  padding-bottom: $small-spacing;
}

.hero-second-level {
  padding-top: $small-spacing * 0.67;
  padding-bottom: $small-spacing * 0.67;
}
