@font-face {
  font-family: 'edsi-icons';
  src: url('../font/edsi-icons.eot?599033');
  src: url('../font/edsi-icons.eot?599033#iefix') format('embedded-opentype'),
       url('../font/edsi-icons.woff2?599033') format('woff2'),
       url('../font/edsi-icons.woff?599033') format('woff'),
       url('../font/edsi-icons.ttf?599033') format('truetype'),
       url('../font/edsi-icons.svg?599033#edsi-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'edsi-icons';
    src: url('../font/edsi-icons.svg?599033#edsi-icons') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "edsi-icons";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-play:before { content: '\e800'; } /* '' */
.icon-address-card:before { content: '\e801'; } /* '' */
.icon-address-book:before { content: '\e802'; } /* '' */
.icon-alarm-clock:before { content: '\e803'; } /* '' */
.icon-box-open:before { content: '\e804'; } /* '' */
.icon-bug:before { content: '\e805'; } /* '' */
.icon-bullseye-arrow:before { content: '\e806'; } /* '' */
.icon-car:before { content: '\e807'; } /* '' */
.icon-chart-pie:before { content: '\e808'; } /* '' */
.icon-ambulance:before { content: '\e809'; } /* '' */
.icon-clock:before { content: '\e80a'; } /* '' */
.icon-analytics:before { content: '\e80b'; } /* '' */
.icon-atom-alt:before { content: '\e80c'; } /* '' */
.icon-award:before { content: '\e80d'; } /* '' */
.icon-baby-carriage:before { content: '\e80e'; } /* '' */
.icon-backpack:before { content: '\e80f'; } /* '' */
.icon-angle-down:before { content: '\e810'; } /* '' */
.icon-basketball-ball:before { content: '\e811'; } /* '' */
.icon-angle-right:before { content: '\e812'; } /* '' */
.icon-bed:before { content: '\e813'; } /* '' */
.icon-biking:before { content: '\e814'; } /* '' */
.icon-birthday-cake:before { content: '\e815'; } /* '' */
.icon-bolt:before { content: '\e816'; } /* '' */
.icon-arrow-alt-down:before { content: '\e819'; } /* '' */
.icon-arrow-alt-left:before { content: '\e81e'; } /* '' */
.icon-arrow-alt-right:before { content: '\e81f'; } /* '' */
.icon-arrow-alt-up:before { content: '\e828'; } /* '' */
.icon-arrows-alt:before { content: '\e83e'; } /* '' */
.icon-badge-check:before { content: '\e848'; } /* '' */
.icon-balance-scale:before { content: '\e84a'; } /* '' */
.icon-bars:before { content: '\e851'; } /* '' */
.icon-battery-bolt:before { content: '\e857'; } /* '' */
.icon-bell:before { content: '\e861'; } /* '' */
.icon-binoculars:before { content: '\e863'; } /* '' */
.icon-book-heart:before { content: '\e86a'; } /* '' */
.icon-book:before { content: '\e86b'; } /* '' */
.icon-briefcase:before { content: '\e87d'; } /* '' */
.icon-building:before { content: '\e880'; } /* '' */
.icon-bullhorn:before { content: '\e881'; } /* '' */
.icon-bullseye:before { content: '\e882'; } /* '' */
.icon-bus:before { content: '\e884'; } /* '' */
.icon-calculator:before { content: '\e885'; } /* '' */
.icon-calendar:before { content: '\e886'; } /* '' */
.icon-camera:before { content: '\e890'; } /* '' */
.icon-caret-down:before { content: '\e897'; } /* '' */
.icon-caret-right:before { content: '\e899'; } /* '' */
.icon-certificate:before { content: '\e8a1'; } /* '' */
.icon-chart-area:before { content: '\e8a2'; } /* '' */
.icon-chart-bar:before { content: '\e8a3'; } /* '' */
.icon-chart-line:before { content: '\e8a4'; } /* '' */
.icon-check:before { content: '\e8a8'; } /* '' */
.icon-chess:before { content: '\e8b8'; } /* '' */
.icon-chevron-down:before { content: '\e8c1'; } /* '' */
.icon-chevron-left:before { content: '\e8c2'; } /* '' */
.icon-chevron-right:before { content: '\e8c3'; } /* '' */
.icon-child:before { content: '\e8c8'; } /* '' */
.icon-chevron-up:before { content: '\e8c9'; } /* '' */
.icon-clipboard-check:before { content: '\e8cc'; } /* '' */
.icon-clipboard-list:before { content: '\e8cd'; } /* '' */
.icon-cogs:before { content: '\e8de'; } /* '' */
.icon-comment-alt-smile:before { content: '\e8e8'; } /* '' */
.icon-comment:before { content: '\e8f5'; } /* '' */
.icon-compass:before { content: '\e8f8'; } /* '' */
.icon-cube:before { content: '\e908'; } /* '' */
.icon-cubes:before { content: '\e909'; } /* '' */
.icon-desktop:before { content: '\e90f'; } /* '' */
.icon-dollar-sign:before { content: '\e913'; } /* '' */
.icon-download:before { content: '\e91c'; } /* '' */
.icon-envelope:before { content: '\e926'; } /* '' */
.icon-exchange-alt:before { content: '\e929'; } /* '' */
.icon-exclamation:before { content: '\e92e'; } /* '' */
.icon-expand-arrows-alt:before { content: '\e930'; } /* '' */
.icon-eye:before { content: '\e93a'; } /* '' */
.icon-female:before { content: '\e93e'; } /* '' */
.icon-file-alt:before { content: '\e941'; } /* '' */
.icon-file-image:before { content: '\e949'; } /* '' */
.icon-filter:before { content: '\e956'; } /* '' */
.icon-flag:before { content: '\e95b'; } /* '' */
.icon-frown:before { content: '\e966'; } /* '' */
.icon-graduation-cap:before { content: '\e971'; } /* '' */
.icon-hand-holding-seedling:before { content: '\e979'; } /* '' */
.icon-hand-paper:before { content: '\e97e'; } /* '' */
.icon-handshake:before { content: '\e98e'; } /* '' */
.icon-hashtag:before { content: '\e98f'; } /* '' */
.icon-heart:before { content: '\e995'; } /* '' */
.icon-home:before { content: '\e99c'; } /* '' */
.icon-hourglass-end:before { content: '\e9a0'; } /* '' */
.icon-image:before { content: '\e9a8'; } /* '' */
.icon-info:before { content: '\e9b2'; } /* '' */
.icon-key:before { content: '\e9b6'; } /* '' */
.icon-life-ring:before { content: '\e9c1'; } /* '' */
.icon-lightbulb:before { content: '\e9c3'; } /* '' */
.icon-link:before { content: '\e9c4'; } /* '' */
.icon-list-ol:before { content: '\e9c7'; } /* '' */
.icon-lock-alt:before { content: '\e9cb'; } /* '' */
.icon-lock-open-alt:before { content: '\e9cc'; } /* '' */
.icon-magnet:before { content: '\e9db'; } /* '' */
.icon-map-marker-alt:before { content: '\e9dd'; } /* '' */
.icon-map:before { content: '\e9e1'; } /* '' */
.icon-meh:before { content: '\e9e8'; } /* '' */
.icon-microphone:before { content: '\e9ed'; } /* '' */
.icon-paper-plane:before { content: '\ea07'; } /* '' */
.icon-pen:before { content: '\ea11'; } /* '' */
.icon-pencil-alt:before { content: '\ea12'; } /* '' */
.icon-percent:before { content: '\ea16'; } /* '' */
.icon-person-dolly-empty:before { content: '\ea18'; } /* '' */
.icon-phone:before { content: '\ea1e'; } /* '' */
.icon-piggy-bank:before { content: '\ea1f'; } /* '' */
.icon-plus:before { content: '\ea2a'; } /* '' */
.icon-podcast:before { content: '\ea2b'; } /* '' */
.icon-puzzle-piece:before { content: '\ea34'; } /* '' */
.icon-quote-left:before { content: '\ea3a'; } /* '' */
.icon-random:before { content: '\ea3e'; } /* '' */
.icon-repeat-alt:before { content: '\ea48'; } /* '' */
.icon-road:before { content: '\ea4f'; } /* '' */
.icon-rocket:before { content: '\ea50'; } /* '' */
.icon-route:before { content: '\ea51'; } /* '' */
.icon-rss:before { content: '\ea53'; } /* '' */
.icon-search:before { content: '\ea5d'; } /* '' */
.icon-share-alt:before { content: '\ea62'; } /* '' */
.icon-share:before { content: '\ea64'; } /* '' */
.icon-ship:before { content: '\ea69'; } /* '' */
.icon-shipping-fast:before { content: '\ea6a'; } /* '' */
.icon-signal:before { content: '\ea77'; } /* '' */
.icon-sitemap:before { content: '\ea78'; } /* '' */
.icon-smile-plus:before { content: '\ea7d'; } /* '' */
.icon-smile:before { content: '\ea7e'; } /* '' */
.icon-star-exclamation:before { content: '\ea90'; } /* '' */
.icon-star:before { content: '\ea92'; } /* '' */
.icon-stopwatch:before { content: '\ea99'; } /* '' */
.icon-suitcase:before { content: '\ea9e'; } /* '' */
.icon-sun:before { content: '\ea9f'; } /* '' */
.icon-tasks:before { content: '\eab1'; } /* '' */
.icon-thermometer-half:before { content: '\eabc'; } /* '' */
.icon-thumbs-down:before { content: '\eac0'; } /* '' */
.icon-thumbs-up:before { content: '\eac1'; } /* '' */
.icon-times:before { content: '\eac9'; } /* '' */
.icon-trash:before { content: '\ead2'; } /* '' */
.icon-tree:before { content: '\ead4'; } /* '' */
.icon-trophy-alt:before { content: '\ead6'; } /* '' */
.icon-umbrella:before { content: '\eae1'; } /* '' */
.icon-university:before { content: '\eae6'; } /* '' */
.icon-user-alt:before { content: '\eaee'; } /* '' */
.icon-user-plus:before { content: '\eafb'; } /* '' */
.icon-users:before { content: '\eb04'; } /* '' */
.icon-watch:before { content: '\eb19'; } /* '' */
.icon-wrench:before { content: '\eb26'; } /* '' */
.icon-brain:before { content: '\eb29'; } /* '' */
.icon-facebook-f:before { content: '\eb95'; } /* '' */
.icon-instagram:before { content: '\ebd1'; } /* '' */
.icon-linkedin-in:before { content: '\ebe8'; } /* '' */
.icon-twitter:before { content: '\ec60'; } /* '' */
.icon-youtube:before { content: '\ec8a'; } /* '' */
.icon-attract:before { content: '\ec8b'; } /* '' */
.icon-retain:before { content: '\ec8c'; } /* '' */
.icon-train:before { content: '\ec8d'; } /* '' */
.icon-cloud-showers-heavy:before { content: '\ec9e'; } /* '' */
.icon-clouds-sun:before { content: '\ec9f'; } /* '' */
.icon-coffee-togo:before { content: '\eca0'; } /* '' */
.icon-coffee:before { content: '\eca1'; } /* '' */
.icon-concierge-bell:before { content: '\eca2'; } /* '' */
.icon-dna:before { content: '\eca3'; } /* '' */
.icon-feather-alt:before { content: '\eca4'; } /* '' */
.icon-flask:before { content: '\eca5'; } /* '' */
.icon-flower-daffodil:before { content: '\eca6'; } /* '' */
.icon-gamepad:before { content: '\eca7'; } /* '' */
.icon-gem:before { content: '\eca8'; } /* '' */
.icon-glasses:before { content: '\eca9'; } /* '' */
.icon-globe-americas:before { content: '\ecaa'; } /* '' */
.icon-globe:before { content: '\ecab'; } /* '' */
.icon-hourglass-half:before { content: '\ecac'; } /* '' */
.icon-hourglass-start:before { content: '\ecad'; } /* '' */
.icon-ice-cream:before { content: '\ecae'; } /* '' */
.icon-infinity:before { content: '\ecaf'; } /* '' */
.icon-island-tropical:before { content: '\ecb0'; } /* '' */
.icon-laptop:before { content: '\ecb1'; } /* '' */
.icon-male:before { content: '\ecb2'; } /* '' */
.icon-map-signs:before { content: '\ecb3'; } /* '' */
.icon-medal:before { content: '\ecb4'; } /* '' */
.icon-megaphone:before { content: '\ecb5'; } /* '' */
.icon-newspaper:before { content: '\ecb6'; } /* '' */
.icon-palette:before { content: '\ecb7'; } /* '' */
.icon-paperclip:before { content: '\ecb8'; } /* '' */
.icon-passport:before { content: '\ecb9'; } /* '' */
.icon-phone-laptop:before { content: '\ecba'; } /* '' */
.icon-question:before { content: '\ecbc'; } /* '' */
.icon-snowflake:before { content: '\ecbd'; } /* '' */
.icon-tasks-alt:before { content: '\ecbe'; } /* '' */
.icon-thermometer-quarter:before { content: '\ecbf'; } /* '' */
.icon-thermometer-three-quarters:before { content: '\ecc0'; } /* '' */
.icon-ticket-alt:before { content: '\ecc1'; } /* '' */
.icon-truck:before { content: '\ecc2'; } /* '' */
.icon-tshirt:before { content: '\ecc3'; } /* '' */
.icon-user-graduate:before { content: '\ecc4'; } /* '' */
.icon-user-tie:before { content: '\ecc5'; } /* '' */
.icon-wallet:before { content: '\ecc6'; } /* '' */
.icon-android:before { content: '\ecc7'; } /* '' */
.icon-apple:before { content: '\ecc8'; } /* '' */
