figure {
  margin: 0;
}

figcaption {
  color: $light-font-color;
  font-size: $base-font-size;
  margin-top: $small-spacing;
  text-align: center;
}

img,
picture {
  margin: 0;
  max-width: 100%;
}

.media-container {
  margin-top: 0;
  margin-bottom: $base-spacing; // Same as paragraph margins
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

@mixin base-overlay($color: $base-shadow-color) {
  background-color: $color;
  content: '';
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
}

@mixin gradient-overlay($color: $base-shadow-color) {
  background-image: linear-gradient(to top, $color, transparent 100%);
  content: '';
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
}